<template>
    <div>
        <div
            v-if="!!record && !!record.current_subscription"
            class="card"
        >
            <div class="card-header">
                <small v-if="showSubscriberName" class="card-subtitle">
                    {{ record.name }}
                </small>
                <div class="card-title h5">
                    {{ price_name }}
                    <a
                        v-if="is_at_least_administrator && !!record.current_subscription.url"
                        :href="record.current_subscription.url"
                        target="_blank"
                        :class="{'ml-4': !small_screen, 'float-right': !small_screen, 'd-block': small_screen, 'mt-4': small_screen}"
                        class="btn btn-sm btn-link stripe-link"
                    >
                        <img
                            src="@/assets/img/stripe.png"
                        />
                    </a>
                </div>
                <small class="card-subtitle">
                    <span v-if="cancelled" class="text-error">{{ translate('Cancelled') }}</span>
                    <span v-else-if="graced" class="text-error">{{ translate('Will terminate on') }}: {{ record.current_subscription.ends_at | nibnut.date }}</span>
                    <span v-else-if="trial" class="text-gray">{{ translate('Trial ends on') }}: {{ record.current_subscription.ends_at | nibnut.date }}</span>
                </small>
            </div>
            <div
                :class="{ 'd-invisible': !active && !graced }"
                class="card-body"
            >
                <div>
                    <span v-if="!!record.pm_type && !!record.pm_last_four && !is_free">{{ translate('Billed {price}, on {card_type} {card_digits}', { price: price_price, card_type: record.pm_type, card_digits: '***' + record.pm_last_four }) }}</span>
                    <span v-else>{{ translate('Free') }}</span>
                    <br v-if="is_discounted && discount_conditions(record.current_subscription.coupon, price)" />
                    <small v-if="is_discounted && discount_conditions(record.current_subscription.coupon, price)" class="text-gray">
                        {{ discount_conditions(record.current_subscription.coupon, price) }}
                    </small>
                </div>
                <default-button
                    v-if="active && !!record.pm_type && !!record.pm_last_four"
                    flavor="link"
                    size="sm"
                    :block="true"
                    class="mt-4"
                    @click.prevent="setup_subscription('cc')"
                >
                    {{ translate('Update Credit Card Information') }}
                </default-button>
            </div>
            <div class="card-footer">
                <default-button
                    v-if="(!price || not_subscribed || cancelled)"
                    :waiting="loading === 'change'"
                    color="primary"
                    :block="true"
                    @click.prevent="setup_subscription('change')"
                >
                    {{ translate('Start Subscription') }}
                </default-button>
                <div
                    v-else-if="active"
                >
                    <default-button
                        :waiting="loading === 'unsubscribe'"
                        flavor="link"
                        color="error"
                        size="sm"
                        :block="true"
                        @click.prevent="setup_subscription('unsubscribe')"
                    >
                        {{ translate('Cancel Subscription') }}
                    </default-button>

                    <default-button
                        :waiting="loading === 'change'"
                        color="primary"
                        :block="true"
                        class="mt-2"
                        @click.prevent="setup_subscription('change')"
                    >
                        {{ translate('Change Subscription') }}
                    </default-button>
                </div>
                <default-button
                    v-else-if="graced"
                    :waiting="loading === 'resume'"
                    color="primary"
                    :block="true"
                    @click.prevent="setup_subscription('resume')"
                >
                    {{ translate('Resume Subscription') }}
                </default-button>
            </div>
        </div>

        <subscribe-dialog
            :show.sync="setting_up_subscription"
            :subscriber="record"
            :mode="subscription_mode"
            @subscribing="trigger_subscription"
        />
    </div>
</template>

<script type="text/javascript">
import profile_utilities from "../mixins/ProfileUtilities"
import handles_subscriptions from "./HandlesSubscriptions"
import ui_utilities from "../mixins/UiUtilities"

import SubscribeDialog from "./SubscribeDialog"
import { DefaultButton } from "../components"

export default {
    mixins: [profile_utilities, handles_subscriptions, ui_utilities],
    components: {
        SubscribeDialog,
        DefaultButton
    },
    methods: {
        setup_subscription (mode) {
            if(mode === "resume") this.trigger_subscription()
            else {
                this.subscription_mode = mode
                this.subscription_notes = ""
                this.setting_up_subscription = true
            }
        },
        trigger_subscription () {

        }
    },
    computed: {
        price () {
            if(!this.record || !this.record.current_subscription) return null
            return this.record.current_subscription.price
        },
        price_name () {
            if(!this.price) return this.translate("No price")
            return this.price.nickname
        },
        is_discounted () {
            return this.record && this.record.current_subscription && this.record.current_subscription.coupon
        },
        is_free () {
            if(!this.price) return false
            return this.stripe_price_price(this.price, this.record.current_subscription.coupon, true) === 0
        },
        price_price () {
            if(!this.price) return ""
            return this.stripe_price_price(this.price, this.record.current_subscription.coupon)
        },
        trial () {
            if(!this.price) return ""
            return this.record.current_subscription.status === "trial"
        },
        not_subscribed () {
            if(!this.record || !this.record.current_subscription || !this.record.current_subscription.price) return ""
            return this.record.current_subscription.status === "not-subscribed"
        },
        active () {
            if(!this.record || !this.record.current_subscription || !this.record.current_subscription.price) return ""
            return this.record.current_subscription.status === "active"
        },
        cancelled () {
            if(!this.price) return ""
            return this.record.current_subscription.status === "cancelled"
        },
        graced () {
            if(!this.price) return ""
            return this.record.current_subscription.status === "grace-period"
        }
    },
    props: {
        record: { // web app!
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(change|unsubscribe|resume)$/),
            default: ""
        },
        showSubscriberName: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            setting_up_subscription: false,
            subscription_mode: "change",
            subscription_notes: ""
        }
    }
}
</script>
