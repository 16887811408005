<template>
    <modal-dialog
        id="contact-editor"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span v-if="!!record_id && !!edited_record" lass="h5">{{ translate("Edit Contact {name}", { name: string_concat(' ', edited_record.first_name, edited_record.last_name) }) }}</span>
            <span v-else class="h5">{{ translate("New Contact") }}</span>
            <contact-access-button
                v-if="edited_record"
                :contact="edited_record"
                size="sm"
                class="ml-4"
                @click.prevent.stop="maybe_toggle_contact_access(edited_record)"
            />
            <contact-report-recipient-button
                :contact="edited_record"
                size="sm"
                class="ml-2"
                @click.prevent.stop="toggle_contact_report_recipient(edited_record)"
            />
        </template>

        <div v-if="!!edited_record" class="columns">
            <div class="column col-6 col-sm-12">
                <form-input
                    id="first_name"
                    name="first_name"
                    v-model="edited_record.first_name"
                    :required="true"
                    :saving="saving('first_name')"
                    :error="has_error('first_name')"
                    @input="save"
                >
                    <template v-slot:label>{{ translate("First Name") }}</template>
                </form-input>
            </div>
            <div class="column col-6 col-sm-12">
                <form-input
                    id="last_name"
                    name="last_name"
                    v-model="edited_record.last_name"
                    :required="true"
                    :saving="saving('last_name')"
                    :error="has_error('last_name')"
                    @input="save"
                >
                    <template v-slot:label>{{ translate("Last Name") }}</template>
                </form-input>
            </div>
        </div>
        <form-input
            v-if="!!edited_record"
            id="email"
            name="email"
            type="email"
            v-model="edited_record.email"
            :required="true"
            :saving="saving('email')"
            :error="has_error('email')"
            @input="save"
            class="mt-2"
        >
            <template v-slot:label>{{ translate("Email") }}</template>
        </form-input>

        <div class="mt-4">
            <feedback-button
                v-if="!!edited_record && !!edited_record.user_id"
                :status="send_password_status"
                :waiting="sending_password"
                @click.prevent="send_reset_password"
                class="mt-2"
            >
                {{ translate("Send a 'Reset Password' Link") }}
                <template v-slot:message>
                    <div
                        :class="{ 'text-success': (send_password_status === true), 'text-error': (send_password_status === false) }"
                        class="text-center"
                    >
                        {{ send_password_message }}
                    </div>
                </template>
            </feedback-button>
        </div>
        <div
            v-if="is_at_least_administrator && user"
            class="mt-8"
        >
            <h6>{{ translate("Admin Zone") }}</h6>
            <div class="columns">
                <div
                    class="column col-sm-12 mb-2"
                >
                    <form-select
                        id="role"
                        name="role"
                        v-model="user.role"
                        :data-source="filtered_roles"
                        :required="true"
                        :saving="saving('role')"
                        :error="has_error('role')"
                        @input="save_user"
                    >
                        <template v-slot:label>{{ translate("Role") }}</template>
                    </form-select>
                </div>
                <div
                    v-if="!!user && !!user.invitation_expires_at"
                    class="column col-sm-12 mb-2 text-center"
                >
                    <label
                        :class="{'text-error': invitation_expired, 'text-light-gray': !invitation_expired}"
                        class="form-label"
                    >
                        <span v-if="invitation_expired">{{ translate("Invitation expired on") }}</span>
                        <span v-else>{{ translate("Invitation valid until") }}</span>
                        {{ user.invitation_expires_at | nibnut.date("YYYY-MM-DD [@] HH:mm") }}
                    </label>
                    <default-button
                        :title="translate('Re-send invitation')"
                        :block="true"
                        @click.prevent="resend_invitation(user)"
                    >
                        <app-icon glyph="forward" /> {{ translate('Re-send invitation') }}
                    </default-button>
                </div>
            </div>
        </div>
        <div v-if="!!record_id" class="text-center mt-8">
            <default-button
                flavor="link"
                color="error"
                @click.prevent="confirm_record_delete"
            >
                {{ translate("Delete Contact") }}
            </default-button>
        </div>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />
        <confirmation
            v-if="confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
        <invite-user-dialog
            :show.sync="inviting"
            :invitation-data="invitation_data"
            :client-id="invitation_client_id"
            @invited="set_contact_access($event.id)"
        />

        <template v-slot:footer>
            <div v-if="!record_id" class="text-center">
                <default-button
                    class="mr-2"
                    @click.prevent="close"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    class="ml-2"
                    @click.prevent="record_save"
                >
                    {{ translate("Save") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import is_record_editor from "@/nibnut/mixins/IsRecordEditor"
import is_user_inviter from "@/nibnut/mixins/IsUserInviter"
import string_utilities from "@/nibnut/mixins/StringUtilities"
import handles_contact_access from "@/custom/mixins/HandlesContactAccess"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"

export default {
    name: "ContactEditor",
    mixins: [is_record_editor, is_user_inviter, string_utilities, handles_contact_access],
    components: {
        ModalDialog,
        FormInput,
        FormSelect,
        DefaultButton,
        FeedbackButton
    },
    watch: {
        contactId: "reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.contactId
        },
        post_load () {
            if(!this.record_id) this.edited_record.client_id = this.clientId
        },
        send_reset_password () {
            this.sending_password = true
            this.$store.dispatch("SEND_PASSWORD_RESET", {
                email: this.edited_record.email
            }).then(data => {
                this.send_password_status = true
                this.send_password_message = data.message
            }).catch(error => {
                this.send_password_status = false
                this.send_password_message = error.message
            }).then(() => {
                this.sending_password = false
            })
        },
        record_save () {
            const edited_record = this.edited_record
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(() => {
                this.close()
            })
        },
        confirm_record_delete () {
            const edited_record = this.edited_record
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: this.string_concat(" ", edited_record.first_name, edited_record.last_name) }),
                    message: this.translate("Do you really want to delete this contact? It will also delete it on Harvest, and there is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted (record) {
            this.close()
        },
        save_user (value, field) {
            const user = this.user
            if(!user) return
            this.save_field_for_record_id("user", user.id, value, field)
        },
        close () {
            this.shown = false
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        cleanup_before_reload () {
            return false
        },
        user () {
            const edited_record = this.edited_record
            if(!edited_record || !edited_record.user_id) return null
            return this.entity_record("user", edited_record.user_id)
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            if(!this.is_developer) return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
            return this.roles
        },
        invitation_expired () {
            const record = this.edited_record
            if(!!record && !!record.invitation_expires_at) return this.$dayjs(record.invitation_expires_at).isBefore()
            return false
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        contactId: {
            type: Number,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "contact",
            entityName: "contact:::contacts",
            relation_ids: ["user"],
            sending_password: false,
            send_password_status: null,
            send_password_message: ""
        }
    }
}
</script>
