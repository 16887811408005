<template>
    <div v-if="!!record">
        <backups
            :logs="installation_logs"
            :from-date="record.period_from"
            :to-date="record.period_to"
            :available-backup-dates="record.available_backup_dates"
        />
        <upgrades
            :logs="server_logs"
            :from-date="record.period_from"
            :to-date="record.period_to"
            class="mt-8"
        />

        <div class="card text-gray text-small mt-8">
            <div class="card-body">
                <div class="columns">
                    <div class="column col-4 col-sm-12">
                        <span>
                            {{ translate("PHP") }}:
                        </span>
                        <strong>
                            {{ record.computed_php_version }}
                        </strong>
                        <span v-if="!!latest_server_log.max_php_version && (record.computed_php_version < latest_server_log.max_php_version)">
                            ({{ latest_server_log.max_php_version }})*
                        </span>
                    </div>
                    <div class="column col-4 col-sm-12">
                        <span>
                            {{ translate("Laravel") }}:
                        </span>
                        <strong>
                            {{ record.computed_laravel_version }}
                        </strong>
                        <span v-if="record.computed_laravel_version < latest_server_log.max_laravel_version">
                            ({{ latest_server_log.max_laravel_version }})*
                        </span>
                    </div>
                    <div class="column col-4 col-sm-12">
                        <span>
                            {{ translate("Last backup") }}:
                        </span>
                        <strong>
                            {{ latest_installation_log.generated_on | nibnut.date }}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="(record.computed_php_version < latest_server_log.max_php_version) || (record.computed_laravel_version < latest_server_log.max_laravel_version)"
            class="text-gray text-small text-center mt-4"
        >
            * {{ translate("Will soon be upgraded, as per maintenance schedule") }}
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"
import { mapGetters } from "vuex"

import Backups from "./Backups"
import Upgrades from "./Upgrades"

export default {
    name: "InstallationReport",
    components: {
        Backups,
        Upgrades
    },
    computed: {
        ...mapGetters(["entity_record", "entity_records"]),
        installation () {
            if(!this.record) return {}
            return this.entity_record("installation", this.record.installation_id)
        },
        installation_logs () {
            const installation = this.installation
            if(!installation || !installation.id) return []
            return this.entity_records("installation_log").filter(installation_log => installation_log.installation_id === installation.id)
        },
        server_logs () {
            const installation = this.installation
            if(!installation || !installation.server_id) return []
            return this.entity_records("server_log").filter(server_log => server_log.server_id === installation.server_id)
        },
        latest_server_log () {
            const server_logs = this.server_logs
            if(!server_logs.length) return {}
            return orderBy(server_logs, "generated_on", "desc")[0]
        },
        latest_installation_log () {
            const installation_logs = this.installation_logs
            if(!installation_logs.length) return {}
            return orderBy(installation_logs, "generated_on", "desc")[0]
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    }
}
</script>
