import subscription_string_utilities from "./SubscriptionStringUtilities"

const handles_subscriptions = {
    mixins: [subscription_string_utilities],
    methods: {
        setup_stripe_context (stripe_elements, stripe_card) {
            this.stripe_elements_component = stripe_elements
            this.stripe_card_component = stripe_card
        },
        get_stripe_payment_method (zip, country) {
            if(!this.stripe_elements_component || !this.stripe_card_component) return Promise.reject(this.translate("Credit Card Fields are not usable yet."))
            const stripe_element = this.stripe_card_component.stripeElement

            return this.stripe_elements_component.instance.createPaymentMethod({
                type: "card",
                card: stripe_element,
                billing_details: {
                    address: {
                        postal_code: zip,
                        country
                    }
                }
            }).then(data => {
                if(data.error) return Promise.reject(data.error)
                if(!data.paymentMethod) return Promise.reject(this.translate("Payment service error. Please try again."))
                return Promise.resolve(data.paymentMethod.id)
            })
        }
    },
    data () {
        return {
            stripe_elements_component: null,
            stripe_card_component: null,
            default_price_id: 0,
            selected_price: null,
            coupon: null
        }
    }
}

export default handles_subscriptions
