<template>
    <router-link
        v-if="is_route"
        :to="href"
        :target="target ? target : null"
        :rel="rel_value"
        active-class="active"
        v-on="$listeners"
    >
        <slot></slot>
    </router-link>
    <a
        v-else
        :href="href"
        :target="target ? target : null"
        :rel="rel_value"
        v-on="$listeners"
    >
        <slot></slot>
    </a>
</template>

<script>
export default {
    name: "BaseLink",
    computed: {
        is_route () {
            return typeof this.href === "object"
        },
        rel_value () {
            if(this.target === "_blank") return "noopener"
            return false
        }
    },
    props: {
        href: {
            validator: prop => !!prop && ((typeof prop === "string") || ((typeof prop === "object") && !!prop.name)),
            required: true
        },
        target: {
            type: String,
            default: ""
        }
    }
}
</script>
