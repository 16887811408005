const color_utilities = {
    methods: {
        color_name_to_hex (color_name) {
            const matches = color_name.match(/^(.+?)(?:::(light))?$/)
            color_name = matches ? matches[1] : color_name
            const light = matches ? (matches[2] === "light") : false
            // UICatalog Generate code: Paste here
            let hex = "#62ab45"
            switch (color_name) {
            case "secondary":
                hex = "#b8be67"
                break
            case "tertiary":
                hex = "#543820"
                break
            case "dark":
                hex = "#303742"
                break
            case "gray":
                hex = "#f7f8f9"
                break
            case "light":
                hex = "#ffffff"
                break
            case "success":
                hex = "#32b643"
                break
            case "warning":
                hex = "#ffb700"
                break
            case "error":
                hex = "#e85600"
                break
            }
            return light ? `${hex}40` : hex
        }
    }
}

export default color_utilities
