export const SET_APP_CONTEXT = "SET_APP_CONTEXT"
export const SET_PROFILE = "SET_PROFILE"
export const SET_RECORDS = "SET_RECORDS"
export const UNSET_RECORDS = "UNSET_RECORDS"
export const SET_RECORD = "SET_RECORD"
export const SET_RECORD_SHELL = "SET_RECORD_SHELL"
export const SET_EDITED_RECORD = "SET_EDITED_RECORD"
export const UNSET_RECORD = "UNSET_RECORD"
export const HISTORY_PUSH = "HISTORY_PUSH"
export const HISTORY_POP = "HISTORY_POP"

export const EDIT_RECORD = "EDIT_RECORD"

export const SET_ROUTE_STATE = "SET_ROUTE_STATE"

export const CONFIRM = "CONFIRM"
