<template>
    <page
        id="clients-list"
        :title="translate('Client:::Clients', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <data-table
            id="clients-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.id)"
        >
            <template
                v-slot:buttons
            >
                <default-button
                    flavor="link"
                    :waiting="syncing"
                    class="ml-2"
                    @click.prevent="synchronize"
                >
                    <img src="@/assets/img/harvest-logo-icon.webp" class="la" />
                </default-button>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6 :class="{ 'text-gray': !row.is_active }">{{ row.name }}</h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    :class="{ 'text-gray': !row.is_active }"
                    v-if="field === 'name'"
                >
                    <harvest-button
                        main-endpoint="clients"
                        :main-id="row.remote_id"
                        class="mr-4"
                    />
                    {{ row.name }}
                </div>
                <span
                    :class="{ 'text-gray': !row.is_active }"
                    v-else
                >
                    {{ row[field] }}
                </span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities } from "@/nibnut/mixins"

import {
    DefaultButton
} from "@/nibnut/components"
import {
    HarvestButton
} from "@/custom/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities],
    components: {
        DefaultButton,
        HarvestButton
    },
    methods: {
        synchronize () {
            const data = {
                fields: this.fields,
                relation_ids: this.relation_ids
            }

            this.syncing = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: this.entity,
                    action: "records/synchronize",
                    data
                }
            ).then(response => {
                this.current_records_ids = response.record_ids

                this.set_state_values({
                    total: response.total || 0,
                    found: response.found || 0
                })

                this.post_load(response)
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.syncing = false
            })
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        }
    },
    data () {
        return {
            entity: "client",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                currency: { label: "Currency", sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            syncing: false
        }
    }
}
</script>
