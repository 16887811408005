<template>
    <atom-spinner
        v-if="(size === 'lg')"
        :color="color_name_to_hex(color)"
        v-bind="spinner_props"
        class="loader"
    />
    <circles-to-rhombuses-spinner
        v-else
        :color="color_name_to_hex(color)"
        :circle-size="circle_size"
        v-bind="spinner_props"
        class="loader"
    />
</template>

<script type="text/javascript">
import color_utilities from "@/custom/mixins/ColorUtilities"

// https://github.com/epicmaxco/epic-spinners#vuejs-components-list
import { AtomSpinner, CirclesToRhombusesSpinner } from "epic-spinners"

export default {
    name: "Loader",
    mixins: [color_utilities],
    components: {
        AtomSpinner,
        CirclesToRhombusesSpinner
    },
    computed: {
        spinner_props () {
            const { size, color, ...spinner_props } = this.$props
            return spinner_props
        },
        circle_size () {
            if(this.size === "lg") return 13
            if(this.size === "md") return 10
            return 8
        }
    },
    props: {
        size: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(xs|sm|md|lg)$/),
            default: "md"
        },
        color: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(gray|light|primary)$/),
            default: "primary"
        }
        // plus any epic-spinner attributes
    }
}
</script>
