<template>
    <modal-dialog
        id="invite-user"
        :dismissable="!sending_invitation"
        :show.sync="shown"
    >
        <template v-slot:title><span class="h5">{{ translate("Invite User") }}</span></template>
        <base-form
            v-if="!!invitee.expires_in"
            :has-required-fields="true"
            @submit.prevent="send_invitation"
        >
            <div class="columns">
                <div class="column col-6 col-md-12 mb-2">
                    <form-input
                        id="email"
                        type="email"
                        name="email"
                        v-model="invitee.email"
                        :required="true"
                        :error="has_error('email')"
                    >
                        <template v-slot:label>{{ translate("Email") }}</template>
                    </form-input>
                </div>
                <div class="column col-6 col-md-12 mb-2">
                    <form-select
                        id="role"
                        name="role"
                        v-model="invitee.role"
                        :data-source="filtered_roles"
                        :required="true"
                        :error="has_error('role')"
                    >
                        <template v-slot:label>{{ translate("Role") }}</template>
                    </form-select>
                </div>
            </div>

            <div class="columns">
                <div class="column col-6 col-lg-12 mb-2">
                    <form-input
                        id="first_name"
                        name="first_name"
                        v-model="invitee.first_name"
                        :required="false"
                        :error="has_error('first_name')"
                    >
                        <template v-slot:label>{{ translate("First Name") }}</template>
                    </form-input>
                </div>
                <div class="column col-6 col-lg-12 mb-2">
                    <form-input
                        id="last_name"
                        name="last_name"
                        v-model="invitee.last_name"
                        :required="false"
                        :error="has_error('last_name')"
                    >
                        <template v-slot:label>{{ translate("Last Name") }}</template>
                    </form-input>
                </div>
            </div>

            <div class="columns">
                <div class="column col-12">
                    <invitation-expiries
                        id="expires_in"
                        name="expires_in"
                        v-model="invitee.expires_in"
                        :required="true"
                        :error="has_error('expires_in')"
                    />
                </div>
            </div>

            <div class="columns">
                <div class="column col-6 col-md-12 mb-2">
                    <form-select
                        id="web_app_id"
                        name="web_app_id"
                        v-model="invitee.web_app_id"
                        data-source="web_app"
                        :data-source-params="{ client_id: clientId }"
                        :show-all="!!clientId"
                        :required="true"
                        :error="has_error('web_app_id')"
                    >
                        <template v-slot:label>{{ translate("App") }}</template>
                    </form-select>
                </div>
                <div class="column col-6 col-md-12 mb-2">
                    <form-input
                        id="trial_period"
                        type="number"
                        name="trial_period"
                        v-model="invitee.trial_period"
                        :required="false"
                        :error="has_error('trial_period')"
                    >
                        <template v-slot:label>{{ translate("Trial Period") }}</template>
                        <template v-slot:right_addon>
                            <span class="input-group-addon">{{ translate("month(s)") }}</span>
                        </template>
                    </form-input>
                </div>
            </div>
        </base-form>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :waiting="sending_invitation"
                    :disabled="sending_invitation"
                    color="primary"
                    @click.prevent="send_invitation"
                >
                    {{ translate("Send Invitation") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapState } from "vuex"

import crud_utilities from "@/nibnut/mixins/CrudUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import InvitationExpiries from "@/nibnut/components/Inputs/InvitationExpiries"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "InviteUserDialog",
    mixins: [crud_utilities, misc_utilities],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        InvitationExpiries,
        DefaultButton
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            if(this.show) {
                this.record_shell(this.entity).then(record => {
                    this.invitee = {
                        ...record,
                        ...this.invitationData,
                        expires_in: this.app_context.settings.default_invitation_delay
                    }
                })
            }
        },
        invitation_expired (row) {
            if(!!row && !!row.invitation_expires_at) return this.$dayjs(row.invitation_expires_at).isBefore()
            return false
        },
        send_invitation () {
            this.sending_invitation = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: {
                    ...this.invitee,
                    invitation_data: {
                        price_id: this.selected_price ? this.selected_price.id : 0,
                        coupon_id: this.coupon ? this.coupon.id : 0,
                        trial_period: 0
                    }
                }
            }).then(record => {
                this.shown = false
                this.invitee = {}
                this.$emit("invited", record)
            }).catch(this.receive_error).then(() => {
                this.sending_invitation = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        invitationData: {
            type: Object,
            default () {
                return {}
            }
        },
        clientId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "user",
            invitee: {},
            sending_invitation: false
        }
    }
}
</script>
