<template>
    <base-link
        :href="email | nibnut.mailto"
        v-on="$listeners"
    >
        <slot>{{ email }}</slot>
    </base-link>
</template>

<script>
import BaseLink from "./BaseLink"

export default {
    name: "MailtoLink",
    components: {
        BaseLink
    },
    props: {
        email: {
            type: String,
            validator: prop => !!prop,
            required: true
        }
    }
}
</script>
