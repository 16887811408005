<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ webApp.name }}
            </div>
        </div>
        <div class="card-body">
            <div v-if="!webApp.is_active" class="empty">
                <div class="empty-icon">
                    <app-icon glyph="lock" size="5x" />
                </div>
                <p class="empty-title h5">{{ translate("Your maintenance plan subscription is expired.") }}</p>
                <div class="empty-action">
                    <default-button
                        color="primary"
                        @click.prevent="setup_subscription('change')"
                    >
                        {{ translate('Start Subscription...') }}
                    </default-button>
                </div>
            </div>
            <div v-else-if="(state.found <= 0) && (state.total === 0)" class="empty">
                <div class="empty-icon">
                    <app-icon glyph="bot" size="5x" />
                </div>
                <p class="empty-title h5">{{ translate("Your first maitenance report will show here at the end of the month.") }}</p>
                <p class="empty-subtitle">{{ translate("You will also receive a notification by email... Stay tuned!") }}</p>
            </div>
            <installation-report
                v-else-if="(state.found > 0)"
                :record="filtered_rows[0]"
            />
            <subscribe-dialog
                :show.sync="setting_up_subscription"
                :subscriber="webApp"
                mode="change"
            />
        </div>
        <div
            v-if="(state.found > 0) && (nb_pages > 1)"
            class="card-footer columns"
        >
            <div class="column">
                <default-button
                    v-if="state.page > 1"
                    flavor="link"
                    @click="goto_page(state.page - 1)"
                >
                    <app-icon glyph="chevron-left" />
                </default-button>
            </div>
            <div class="column text-center">
                {{ filtered_rows[0].period_from | nibnut.date("MMMM") }}
            </div>
            <div class="column text-right">
                <default-button
                    v-if="state.page < nb_pages"
                    flavor="link"
                    @click="goto_page(state.page + 1)"
                >
                    <app-icon glyph="chevron-right" />
                </default-button>
            </div>
        </div>
    </div>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"

import InstallationReport from "@/custom/components/InstallationReport/View"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import AppIcon from "@/nibnut/components/AppIcon"
import SubscribeDialog from "@/nibnut/Stripe/SubscribeDialog"

export default {
    name: "InstallationReports",
    mixins: [is_remote_data_table_source],
    components: {
        InstallationReport,
        DefaultButton,
        AppIcon,
        SubscribeDialog
    },
    watch: {
        webApp: "refresh"
    },
    methods: {
        setup_subscription () {
            this.subscription_notes = ""
            this.setting_up_subscription = true
        }
    },
    computed: {
        state_identifier () {
            return `reports-${this.webApp.id}`
        },
        can_refresh () {
            return !!this.profile_id && !!this.webApp.id
        },
        fields () {
            return ["fieldset::dashboard", "ns::installation;fieldset::dashboard"]
        }
    },
    props: {
        webApp: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "installation_report",
            entityName: "report:::reports",
            relation_ids: ["installation", "installation_log", "server_log"],
            default_state: {
                per_page: 1,
                page: 1,
                sort_by: "period_from",
                sort_dir: "desc",
                filter_on: "web_app_id",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: -1
            },
            setting_up_subscription: false,
            subscription_notes: ""
        }
    }
}
</script>
