const string_utilities = {
    methods: {
        pricing_terms (stripe_price, interval, interval_count, format = "0,0.00", force_as_amount = false) {
            if(!stripe_price && !force_as_amount) return this.translate("Free")
            let text = this.nibnut_filter("nibnut.currency", [stripe_price / 100, format, force_as_amount ? "" : this.translate("Free")])
            if(interval) {
                text += " / "
                if(interval_count > 1) text += `${interval_count} ${interval}s`
                else text += `${interval}`
            }
            return text
        },
        price_terms (stripe_price, format = "0,0.00", force_as_amount = false) {
            return this.pricing_terms(stripe_price.amount, stripe_price.interval, stripe_price.interval_count, format, force_as_amount)
        },
        stripe_price_regular_price (stripe_price) {
            if(!stripe_price) return 0
            return this.price_terms(stripe_price)
        },
        stripe_price_price (stripe_price, coupon, raw, trial_period = 0) {
            let price = 0
            if(!!stripe_price && !trial_period) {
                price = stripe_price.amount / 100
                if(coupon) {
                    if(coupon.amount_off) price -= (coupon.amount_off / 100)
                    else price -= price * (coupon.percent_off / 100)
                    if(price < 0) price = 0
                }
            }
            if(raw) return price
            return this.pricing_terms(price * 100, stripe_price.interval, stripe_price.interval_count)
        },
        string_concat () {
            // (separator, component1, component2, ...)
            if(arguments.length < 2) return ""
            const separator = arguments[0]
            const components = Array.from(arguments).slice(1)
            return components.filter(component => !!component).join(separator)
        },
        address_block_line (line, address) {
            if(line === 1) {
                return address.line1
            } else if(line === 2) {
                return this.string_concat(", ", address.city, this.string_concat(" ", address.state, address.zip))
            }
            return ""
        },
        address_one_line (address) {
            return this.string_concat(", ", this.address_block_line(1, address), this.address_block_line(2, address))
        },
        full_name (record) {
            return this.string_concat(" ", record.first_name, record.last_name)
        }
    },
    computed: {
        app_version () {
            // eslint-disable-next-line
            if(process.env.VUE_APP_VERSION) return process.env.VUE_APP_VERSION
            return ""
        },
        current_year () {
            return this.$dayjs().format("YYYY")
        }
    }
}

export default string_utilities
