<template>
    <section
        v-if="!!logs.length"
        id="installation-report-backups"
    >
        <h5 class="text-center mb-4">
            {{ translate("Files & Database Backups") }}
        </h5>

        <heatmap
            :from-date="fromDate"
            :to-date="toDate"
            :values="values"
            :colors="colors"
            :tooltips="tooltips"
        />
    </section>
</template>

<script>
import is_graph_section from "./IsGraphSection"

import { Heatmap } from "@/custom/components"

export default {
    mixins: [is_graph_section],
    components: {
        Heatmap
    },
    computed: {
        values () {
            const data = {}
            this.logs.forEach(log => {
                const index = `${this.$dayjs(log.generated_on).format("YYYYMMDD")}`
                data[index] = 0
                if(log.backup_valid) {
                    data[index] = 2
                    if(!this.availableBackupDates || (this.availableBackupDates.indexOf(index) < 0)) data[index] = 1
                }
            })
            return data
        },
        colors () {
            return [
                this.color_name_to_hex("error"), // missing / unknown
                this.color_name_to_hex("warning"), // existed, at some point (successful backup, but file deleted to save space)
                this.color_name_to_hex("success") // existing right now
            ]
        },
        tooltips () {
            return [
                this.translate("No Backup"),
                this.translate("Backup No Longer Available"),
                this.translate("Backup Available")
            ]
        }
    },
    props: {
        availableBackupDates: {
            type: Array,
            default () {
                return []
            }
        }
    }
}
</script>

<style lang="scss">
#installation-report-backups {
    h5 {
        margin: 0;
    }
    .chart-container {
        &, .frappe-chart {
            width: 100% !important;
        }
        .frappe-chart {
            .chart-legend {
                display: none;
            }
        }
    }
}
</style>
