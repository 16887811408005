<template>
    <div v-if="(state.found >= 0) && (state.total === 0)" class="empty">
        <div class="empty-icon">
            <app-icon glyph="sprout" size="5x" />
        </div>
        <p class="empty-title h5">{{ translate("You do not have any application monitored... Yet!") }}</p>
    </div>
    <div v-else class="columns">
        <div
            v-for="web_app in filtered_rows"
            :key="web_app.id"
            class="column"
        >
            <installation-reports
                :web-app="web_app"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
import { is_remote_data_table_source } from "@/nibnut/mixins"

import AppIcon from "@/nibnut/components/AppIcon"
import InstallationReports from "./InstallationReports"

export default {
    mixins: [is_remote_data_table_source],
    components: {
        AppIcon,
        InstallationReports
    },
    computed: {
        fields () {
            return ["fieldset::dashboard"]
        }
    },
    data () {
        return {
            entity: "web_app",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: -1
            }
        }
    }
}
</script>
