const routes = [
    { path: "/", name: "home", component: require("@/views/dashboard/View").default, meta: { login_required: true } },
    { path: "/settings", name: "settings", component: require("@/views/Settings").default, meta: { admin_route: true } },
    { path: "/profile", name: "profile", component: require("@/views/user/Edit").default, meta: { login_required: true } },
    { path: "/users", name: "user.list", component: require("@/views/user/List").default, meta: { admin_route: true } },
    { path: "/user/:id?", name: "user.edit", component: require("@/views/user/Edit").default, meta: { admin_route: true } },
    { path: "/account", name: "account", component: require("@/views/client/Edit").default, meta: { login_required: true } },
    { path: "/clients", name: "client.list", component: require("@/views/client/List").default, meta: { admin_route: true } },
    { path: "/client/:id?", name: "client.edit", component: require("@/views/client/Edit").default, meta: { admin_route: true } },
    { path: "/web-app/:id?", name: "web_app.edit", component: require("@/views/web_app/Edit").default, meta: { admin_route: true } }
]

export default routes
