<template>
    <base-select
        ref="field"
        :id="identifier"
        :name="name"
        :value="value"
        :empty-value="emptyValue"
        :empty-label="emptyLabel"
        :options="filtered_options"
        :disabled="disabled"
        :required="required"
        @search="search"
        @input="pick"
    />
</template>

<script>
// import debounce from "lodash/debounce"

import is_nibnut_component from "../../mixins/IsNibnutComponent"
import handles_choices from "../../mixins/HandlesChoices"

import BaseSelect from "./BaseSelect"

export default {
    name: "DefaultSelect",
    mixins: [is_nibnut_component, handles_choices],
    components: {
        BaseSelect
    },
    methods: {
        pick (value, field, option) {
            if(this.adHoc && !value) this.$emit("create", option[this.labelField], field)
            else if(value) this.$emit("input", value, field, option)
        }
    },
    computed: {
        available_options () {
            if((this.option_query.length < this.minOptionQueryLength) && !this.showAll) {
                return this.options.filter(option => option[this.idField] === this.value) || []
            }
            return this.options
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: null
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            options: []
        }
    }
}
</script>
