import ContactAccessButton from "@/custom/components/ContactAccessButton"
import ContactReportRecipientButton from "@/custom/components/ContactReportRecipientButton"
import InviteUserDialog from "@/custom/dialogs/InviteUserDialog"

/*
    <invite-user-dialog
        :show.sync="inviting"
        :invitation-data="invitation_data"
        :client-id="invitation_client_id"
    />
*/

const handles_contact_access = {
    components: {
        ContactAccessButton,
        ContactReportRecipientButton,
        InviteUserDialog
    },
    methods: {
        toggle_contact_access (contact_id) {
            return this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "contact",
                    id: contact_id,
                    action: "toggle-access",
                    data: { fields: this.fields, relation_ids: this.relation_ids },
                    method: "post"
                }
            ).catch(error => {
                this.$error(error.message)
            })
        },
        maybe_toggle_contact_access (contact) {
            if(contact.user_id) this.toggle_contact_access(contact.id) // revoke access
            else {
                this.inviting_contact_id = contact.id
                this.invitation_client_id = contact.client_id
                this.invitation_data = {
                    email: contact.email,
                    first_name: contact.first_name,
                    last_name: contact.last_name
                }
                this.inviting = true
            }
        },
        set_contact_access (user_id) {
            return this.$store.dispatch(
                "RECORD_SAVE",
                {
                    entity: "contact",
                    id: this.inviting_contact_id,
                    data: { user_id }
                }
            ).catch(this.receive_error)
        },
        toggle_contact_report_recipient (contact) {
            return this.$store.dispatch("RECORD_SAVE", {
                entity: "contact",
                id: contact.id,
                data: { is_report_recipient: !contact.is_report_recipient, fields: this.fields, relation_ids: this.relation_ids }
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    data () {
        return {
            inviting_contact_id: 0,
            invitation_data: {},
            invitation_client_id: 0,
            inviting: false
        }
    }
}

export default handles_contact_access
