<template>
    <page
        id="client-editor"
        :title="page_title"
        :back-navigation-fallback="default_back"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <template v-slot:title><div></div></template>
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
        >
            <div class="columns">
                <div
                    :class="{ 'col-7': !!edited_record.id, 'col-md-6': !!edited_record.id, 'col-12': !edited_record.id }"
                    class="column col-sm-12"
                >
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                <div class="h5">
                                    {{ translate("General Information") }}
                                    <harvest-button
                                        main-endpoint="clients"
                                        :main-id="edited_record.remote_id || 0"
                                        class="ml-4"
                                    />
                                </div>
                                <div>
                                    <form-toggle-input
                                        id="is_active"
                                        name="is_active"
                                        type="checkbox"
                                        v-model="edited_record.is_active"
                                        :required="false"
                                        :saving="saving('is_active')"
                                        :error="has_error('is_active')"
                                        @input="save"
                                    >
                                        {{ translate('Active') }}
                                    </form-toggle-input>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="columns">
                                <div class="column col-6 col-sm-12">
                                    <form-input
                                        id="name"
                                        name="name"
                                        v-model="edited_record.name"
                                        :required="true"
                                        :saving="saving('name')"
                                        :error="has_error('name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-6 col-sm-12">
                                    <form-select
                                        id="currency"
                                        name="currency"
                                        v-model="edited_record.currency"
                                        :data-source="currencies"
                                        :required="true"
                                        :saving="saving('currency')"
                                        :error="has_error('currency')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Currency") }}</template>
                                    </form-select>
                                </div>
                            </div>
                            <address-input
                                id="address"
                                name="address"
                                :value="edited_record"
                                :required="false"
                                :errors="errors"
                                class="mt-4"
                                @input="save_address"
                            >
                                <template v-slot:label>{{ translate("Address") }}</template>
                            </address-input>

                            <div class="nibnut-aspect-ratio-box nibnut-ratio-banner">
                                <div>
                                    <image-upload-input
                                        name="image_url"
                                        :url="edited_record.image_url || ''"
                                        :alt="edited_record.name"
                                        :accepts="upload_accepts('image_url')"
                                        :uploading="!!uploading.image_url"
                                        :uploaded="uploaded.image_url||0"
                                        :error="has_error('image_url')"
                                        @upload="upload_file_list('image_url', $event)"
                                        @clear="confirm_clear_file('image_url')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <contacts
                        :client-remote-id="edited_record.remote_id"
                        :client-id="edited_record.id"
                        :contacts="contacts"
                        class="mt-6"
                    />
                </div>
                <div
                    v-if="!!edited_record.id"
                    class="column col-5 col-md-6 col-sm-12"
                >
                    <web-apps
                        v-if="!!edited_record.id"
                        :client-id="edited_record.id"
                        :web-apps="web_apps"
                    />
                </div>
            </div>
            <div
                v-if="!edited_record.id"
                class="text-center mt-8"
            >
                <default-button
                    class="mr-2"
                    @click.prevent="$router.go(-1)"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    class="ml-2"
                    @click.prevent="record_save"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </base-form>
        <div
            class="text-center mt-8"
        >
            <default-button
                flavor="link"
                color="error"
                size="sm"
                @click.prevent="confirm_record_delete"
            >
                {{ translate("Delete Account") }}
            </default-button>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, handles_uploads } from "@/nibnut/mixins"

import {
    BaseForm,
    FormInput,
    FormToggleInput,
    ImageUploadInput,
    FormSelect,
    AddressInput,
    DefaultButton
} from "@/nibnut/components"
import {
    HarvestButton
} from "@/custom/components"
import Contacts from "./Contacts"
import WebApps from "./WebApps"

export default {
    mixins: [is_record_editor_page, handles_uploads],
    components: {
        BaseForm,
        FormInput,
        FormToggleInput,
        ImageUploadInput,
        FormSelect,
        AddressInput,
        DefaultButton,
        HarvestButton,
        Contacts,
        WebApps
    },
    methods: {
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.translate("Do you really want to delete this client? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.push({ name: "client.list" })
        }
    },
    computed: {
        default_back () {
            return { title: this.translate(this.entityName, {}, 2), href: { name: "client.list" } }
        },
        currencies () {
            return Object.values(this.constants("currencies"))
        },
        contacts () {
            if(!this.edited_record || !this.edited_record.id) return []
            return this.entity_records("contact").filter(contact => contact.client_id === this.edited_record.id)
        },
        web_apps () {
            if(!this.edited_record || !this.edited_record.id) return []
            return this.entity_records("web_app").filter(web_app => web_app.client_id === this.edited_record.id)
        }
    },
    data () {
        return {
            entity: "client",
            entityName: "Client:::Clients",
            relation_ids: ["contact", "web_app"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#client-editor {
    .card-title {
        display: flex;
        align-items: center;

        & > div {
            flex: 0 0 auto;

            &.h5 {
                flex: 1 0 auto;
            }

            & > .form-group {
                float: right;
                @media (max-width: $size-sm) {
                    float: none;
                }
            }
        }
    }
}
</style>
