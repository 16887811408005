<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Projects") }}
                <default-button
                    flavor="link"
                    class="ml-2"
                    @click.prevent="synchronize"
                >
                    <app-icon glyph="refresh-cw" :spin="syncing" />
                </default-button>
            </div>
        </div>
        <div class="card-body">
            <data-table
                id="projects-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                @search="search"
                @sort="sort_by"
                @page="goto_page"
                @add="add_row"
                @click="edit_row"
            >
                <template
                    v-slot:buttons
                >
                    <div>
                        <form-select
                            id="filter"
                            name="filter"
                            :value="state.filter"
                            :data-source="filters"
                            :required="false"
                            @input="filter('is_active', $event)"
                        />
                    </div>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === 'name'"
                        :class="{ 'text-gray': !row.is_active }"
                    >
                        {{ row.name }}
                    </div>
                    <div
                        v-else-if="field === '_buttons'"
                        class="text-right"
                    >
                        <default-button
                            v-if="filtered_rows.length > 1"
                            flavor="link"
                            color="error"
                            @click.prevent.stop="row_confirm_delete(row)"
                        >
                            <app-icon glyph="trash" />
                        </default-button>
                        <harvest-button
                            main-endpoint="projects"
                            :main-id="row.remote_id"
                            suffix=""
                            class="ml-2"
                            @click.native.stop
                        />
                    </div>
                    <span
                        v-else
                        :class="{ 'text-gray': !row.is_active }"
                    >
                        {{ row[field] }}
                    </span>
                </template>
            </data-table>

            <project-editor
                :show.sync="editing"
                :project-id="edited_row.id || 0"
                :web-app="webApp"
            />
            <confirmation
                v-if="confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import { is_data_table_source, crud_utilities, confirms } from "@/nibnut/mixins"

import {
    FormSelect,
    DefaultButton,
    AppIcon
} from "@/nibnut/components"
import {
    HarvestButton
} from "@/custom/components"
import {
    ProjectEditor
} from "@/custom/dialogs"

export default {
    name: "Projects",
    mixins: [is_data_table_source, crud_utilities, confirms],
    components: {
        FormSelect,
        DefaultButton,
        AppIcon,
        HarvestButton,
        ProjectEditor
    },
    watch: {
        projects: "refresh"
    },
    methods: {
        synchronize () {
            this.syncing = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "project",
                    action: "records/synchronize",
                    data: {
                        client_id: this.webApp.client_id
                    }
                }
            ).then(() => {
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.syncing = false
            })
        },
        edit_row (row) {
            this.edited_row = row
            this.editing = true
        },
        add_row () {
            this.edit_row({ id: 0 })
        },
        filter_rows (rows) {
            if(this.state.filter_on && this.state.filter) {
                return rows.filter((row) => {
                    if(this.state.filter_on === "is_active") {
                        if(this.state.filter === "-") return true
                        return row.is_active === (this.state.filter === "active")
                    }
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        row_confirm_delete (row) {
            this.edited_row = row
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Project"),
                    message: this.translate("Do you really want to delete the project \"{name}\"? There is no undo...", { name: row.name }),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.row_delete()
            else this.done_confirming()
        },
        row_delete () {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: "project",
                    id: this.edited_row.id
                }
            ).then(() => {
                this.done_confirming()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.edited_row = {}
            })
        }
    },
    computed: {
        state_identifier () {
            return `web-app-${this.webApp.id}-projects`
        },
        rows () {
            return this.projects
        },
        filters () {
            return [
                { id: "-", name: this.translate("All") },
                { id: "active", name: this.translate("Active") },
                { id: "inactive", name: this.translate("Inactive") }
            ]
        }
    },
    props: {
        webApp: {
            type: Object,
            required: true
        },
        projects: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: "is_active",
                filter: "active",
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            syncing: false,
            editing: false,
            edited_row: {}
        }
    }
}
</script>
