<template>
    <div class="nibnut-heatmap">
        <div
            v-for="cell in cells"
            :key="cell.id"
            :class="{ 'out-of-range': cell.out_of_range, tooltip: !!cell.tooltip }"
            :style="{ 'background-color': cell.color }"
            :data-tooltip="cell.tooltip"
        >
            <label>{{ cell.day.format("D") }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: "Heatmap",
    computed: {
        cells () {
            const from = this.$dayjs(this.fromDate)
            const to = this.$dayjs(this.toDate)
            const stop_on = to.endOf("week")
            let date = from.startOf("week")
            let month_title = from.format("MMM")
            let year = from.year()
            const cells = []
            do {
                const cell_id = date.format("YYYYMMDD")
                const value = this.values[cell_id] || 0
                const tooltip = this.tooltips[Math.min(value, this.tooltips.length - 1)]
                cells.push({
                    id: cell_id,
                    month_title,
                    day: date,
                    out_of_range: !date.isBetween(from, to, "day", "[]"),
                    color: this.colors[Math.min(value, this.colors.length - 1)],
                    tooltip: `${this.nibnut_filter("nibnut.date", [date, "MMM D, YYYY"])}${tooltip ? ": " + tooltip : ""}`,
                    value
                })
                date = date.add(1, "day")
                month_title = date.format("MMM")
                const current_year = date.year()
                if(current_year !== year) {
                    year = current_year
                    month_title = date.format("MMM YY")
                }
            } while(date.isSameOrBefore(stop_on))
            return cells
        }
    },
    props: {
        fromDate: {
            type: [String, Object], // dayjs or native date object, or date string
            required: true
        },
        toDate: {
            type: [String, Object], // dayjs or native date object, or date string
            required: true
        },
        values: {
            type: Object, // { <YYYYMMDD>: <value> }
            default () {
                return {}
            }
        },
        colors: {
            type: Array,
            default () {
                return ["light-blue", "blue", "violet", "red", "orange", "yellow", "green", "light-green", "purple", "magenta", "light-grey", "dark-grey"]
            }
        },
        tooltips: {
            type: Array,
            default () {
                return [""]
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-heatmap {
    display: grid;
    grid-template-columns: repeat(7, 2rem);
    column-gap: $layout-spacing-sm;
    row-gap: $layout-spacing-sm;
    justify-content: center;
    line-height: 1.85rem;

    div {
        padding: $control-padding-y-sm $control-padding-x-sm;
        border-radius: 50%;
        text-align: center;

        label {
            font-size: 0.8em;
            color: $light-color;
        }
        &.out-of-range {
            opacity: 0.5;
            label {
                display: hidden;
            }
        }
    }
}
</style>
