<template>
    <picture class="img-responsive">
        <source
            v-for="(image_width, screen_width) in sizes" :key="image_width"
            :media="`(max-width: ${screen_width-1}px)`"
            :srcset="sized_src(image_width)"
        />
        <base-image
            :src="src"
            :alt="alt"
            class="img-responsive"
        />
    </picture>
</template>

<script>
// Imges should be generated for all "sizes" passed in as props (or default ones, which are our CSS breakpoints) and named "<name>-<breakpoint>.(png|jpg|...)"
import BaseImage from "./BaseImage"

export default {
    name: "ResponsiveImage",
    inheritAttrs: false,
    components: {
        BaseImage
    },
    methods: {
        sized_src (image_width) {
            return this.src.replace(/(\.[^.]+$)/i, `-${image_width}$1`)
        }
    },
    props: {
        ...BaseImage.props,
        sizes: {
            type: Object,
            default () {
                return {
                    480: 480,
                    550: 550,
                    840: 840,
                    1100: 1100,
                    1280: 1280,
                    1440: 1440
                }
            }
        }
    }
}
</script>
