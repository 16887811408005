<template>
    <base-link
        href="#close"
        :aria-label="translate('Close')"
        aria-hidden="true"
        @click.prevent="$emit('click')"
    />
</template>

<script>
import BaseLink from "../Links/BaseLink"

export default {
    name: "DismissLink",
    components: {
        BaseLink
    }
}
</script>
