const handles_clipboard = {
    methods: {
        change_clipboard_status (status) {
            this.clipboard_copy_status = status
            if(status !== null) {
                setTimeout(() => {
                    this.clipboard_copy_status = null
                }, this.clipboard_status_delay)
            }
        },
        clipboard_copied () {
            this.change_clipboard_status(true)
        },
        clipboard_error () {
            this.change_clipboard_status(false)
            this.$error(this.clipboard_error_text)
        }
    },
    computed: {
        clipboard_text () {
            return ""
        },
        clipboard_error_text () {
            return this.translate("Clipboard copy failed... :(")
        },
        clipboard_button_color () {
            if(this.clipboard_copy_status === false) return "error"
            return "primary"
        },
        clipboard_button_icon () {
            if(this.clipboard_copy_status === false) return "clipboard-x"
            if(this.clipboard_copy_status === true) return "clipboard-check"
            return "clipboard"
        }
    },
    data () {
        return {
            clipboard_status_delay: 3000,
            clipboard_copy_status: null
        }
    }
}

export default handles_clipboard
