import profile_utilities from "./ProfileUtilities"

const handles_choices = {
    mixins: [profile_utilities],
    mounted () {
        this.set_data_source()
    },
    watch: {
        profile_id: "maybe_load_option",
        value: "maybe_load_option",
        dataSource: "set_data_source"
    },
    methods: {
        load_options () {
            if(this.showAll || (this.option_query.length >= this.minOptionQueryLength)) {
                this.loading_options = true
                return this.$store.dispatch("AUTOSUGGEST", {
                    entity: this.dataSource,
                    context: this.name,
                    data: { ...this.dataSourceParams, value: this.value, query: this.option_query } // value is either one id or an array of ids
                }).then(options => {
                    this.options = options
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading_options = false
                })
            }
        },
        maybe_load_option () {
            if(!!this.profile_id && !!this.dataSource && !!this.value && this.is_remote_source) this.load_options()
        },
        set_data_source () {
            if(this.is_remote_source) this.maybe_load_option()
            else this.options = this.dataSource
        },
        search (query) {
            this.option_query = query.toLowerCase()
            if(this.is_remote_source) this.load_options()
        }
    },
    computed: {
        is_remote_source () {
            return !!this.dataSource && (typeof this.dataSource === "string")
        },
        available_options () {
            // ALL the options available for picking ; usually only filters out the selected values, nothing else
            return this.options
        },
        filtered_options () {
            const options = this.available_options.filter(option => {
                return option[this.labelField].toLowerCase().indexOf(this.option_query.toLowerCase()) >= 0
            })

            if(this.adHoc && (this.option_query.length >= this.minOptionQueryLength)) {
                if(!this.options.find(option => `${option[this.labelField].toLowerCase()}` === this.option_query)) {
                    options.push({ [this.idField]: this.emptyValue, [this.labelField]: this.option_query })
                }
            }

            return options
        }
    },
    props: {
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        dataSource: { // either an array of options (locally sourced) OR an entity name (remote source ; api endpoint /<entity>/autosuggest/<name>)
            validator: prop => !!prop && (Array.isArray(prop) || (typeof prop === "string")),
            required: true,
            default () {
                return []
            }
        },
        dataSourceParams: {
            type: Object,
            default () {
                return {}
            }
        },
        adHoc: {
            type: Boolean,
            default: false
        },
        showAll: {
            type: Boolean,
            default: true
        },
        minOptionQueryLength: {
            type: Number,
            default: 3
        }
    },
    data () {
        return {
            options: [],
            option_query: "",
            loading_options: false
        }
    }
}

export default handles_choices
