import color_utilities from "@/custom/mixins/ColorUtilities"

const is_graph_section = {
    mixins: [color_utilities],
    props: {
        logs: {
            type: Array,
            default () {
                return []
            }
        },
        fromDate: {
            type: String,
            required: true
        },
        toDate: {
            type: String,
            required: true
        }
    }
}

export default is_graph_section
