<template>
    <default-button
        v-if="!!contact && !!contact.id"
        flavor="link"
        :color="contact.user_id ? 'primary' : 'light'"
        :title="translate('Grant or revoke access to the platform')"
        v-bind="button_props"
        v-on="$listeners"
    >
        <app-icon :glyph="contact.user_id ? 'user' : 'user-x'" />
    </default-button>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    name: "ContactAccessButton",
    components: {
        DefaultButton,
        AppIcon
    },
    computed: {
        button_props () {
            const { color, flavor, title, contact, ...button_props } = this.$props
            return button_props
        }
    },
    props: {
        contact: {
            type: Object,
            required: true
        },
        ...DefaultButton.props
    }
}
</script>
