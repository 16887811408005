<template>
    <section
        v-if="!!logs.length"
        id="installation-report-upgrades"
    >
        <h5 class="text-center">{{ translate("Server Upgrades") }}</h5>
        <chart
            v-bind="chart_config"
        />
    </section>
</template>

<script>
import {
    Chart
} from "@/nibnut/components"

import is_graph_section from "./IsGraphSection"

export default {
    mixins: [is_graph_section],
    components: {
        Chart
    },
    computed: {
        chart_config () {
            const labels = []
            const data = [
                { name: this.translate("Additions"), values: [] },
                { name: this.translate("Upgrades"), values: [] },
                { name: this.translate("Downgrades"), values: [] },
                { name: this.translate("Removals"), values: [] },
                { name: this.translate("Holds"), values: [] }
            ]
            const to_date = this.$dayjs(this.toDate)
            let date = this.$dayjs(this.fromDate)
            let current_year = date.year()
            let date_year = current_year
            do {
                let format = "MMM D"
                if(current_year !== date_year) {
                    format = "MMM D, YYYY"
                    current_year = date_year
                }
                labels.push(date.format(format))
                data[0].values.push(0)
                data[1].values.push(0)
                data[2].values.push(0)
                data[3].values.push(0)
                data[4].values.push(0)
                const index = data[0].values.length - 1
                const range_end = date.add(1, "day")

                this.logs.forEach(log => {
                    if(this.$dayjs(log.generated_on).isBetween(date, range_end, "day", "[)")) {
                        data[0].values[index] += log.dist_new + log.new
                        data[1].values[index] += log.dist_upgrades + log.upgrades
                        data[2].values[index] += log.dist_downgrades + log.downgrades
                        data[3].values[index] += log.dist_removals + log.removals
                        data[4].values[index] += log.dist_hold + log.hold
                    }
                })

                date = range_end
                date_year = date.year()
            } while(date.isSameOrBefore(to_date))

            return {
                type: "line",
                labels,
                data,
                height: 160,
                axisOptions: {
                    xAxisMode: "tick",
                    xIsSeries: 1
                },
                colors: [
                    "magenta",
                    "light-green",
                    "violet",
                    "orange",
                    this.color_name_to_hex("error")
                ]
            }
        }
    }
}
</script>
