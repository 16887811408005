/*
<confirmation
    v-if="confirming"
    v-bind="confirmation_props"
    @cancel="done_confirming"
    @confirmed="..."
/>
*/

const confirms = {
    components: {
        Confirmation: () => import("../components/ModalDialog/Confirmation")
    },
    methods: {
        confirm (props, id = true) {
            this.confirmation_props = {
                type: "warning",
                icon: "alert-triangle",
                dismissable: false,
                title: "",
                message: "",
                cancel: this.translate("No"),
                ok: this.translate("Yes"),
                ...props
            }
            console.log(this.confirmation_props, props)
            this.confirming = id
        },
        done_confirming () {
            this.confirming = false
            this.confirmation_props = {}
        }
    },
    data () {
        return {
            confirming: false, // true or confirmation ID
            confirmation_props: {}
        }
    }
}

export default confirms
