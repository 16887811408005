<template>
    <a
        v-if="!!mainId"
        :href="href"
        target="_blank"
        :title="translate('See record on Harvest')"
        class="btn btn-link btn-harvest"
        @click.stop
    >
        <img src="@/assets/img/harvest-logo-icon.webp" />
    </a>
</template>

<script>
export default {
    name: "HarvestButton",
    computed: {
        href () {
            let href = `https://nibnut.harvestapp.com/${this.mainEndpoint}/${this.mainId}`
            if(this.subId && this.subEndpoint) href += `/${this.subEndpoint}/${this.subId}`
            if(this.suffix) return `${href}/${this.suffix}`
            return href
        }
    },
    props: {
        mainEndpoint: {
            type: String,
            required: true
        },
        mainId: {
            type: Number,
            required: true
        },
        subEndpoint: {
            type: String,
            default: ""
        },
        subId: {
            type: Number,
            default: 0
        },
        suffix: {
            type: String,
            default: "edit"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.btn.btn-harvest {
    padding: 0;
    height: auto;
    line-height: normal;
    border: 0;

    img {
        width: 1rem;
    }
}
</style>
