<template>
    <modal-dialog
        id="installation-report-editor"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <div v-if="!!record_id && !!edited_record">
                <div class="h5 text-gray">{{ installation.name }}</div>
                <div class="h5">{{ edited_record.period_from | nibnut.date }} - {{ edited_record.period_to | nibnut.date }}</div>
                <div class="text-small text-gray">
                    <span v-if="!!edited_record.published_at">{{ translate("Published on ") }} {{ edited_record.published_at | nibnut.date("YYYY-MM-DD @ HH:mm:ss") }}</span>
                    <span v-else>{{ translate("Not published") }}</span>
                </div>
            </div>
        </template>

        <installation-report
            :record="edited_record"
        />

        <form-editor
            v-if="edited_record"
            id="notes"
            :data-version="edited_record.id"
            name="notes"
            v-model="edited_record.notes"
            size="lg"
            :required="false"
            :tools="[ ['bold', 'italic', 'strike'] ]"
            @input="save"
        >
            <template v-slot:label>{{ translate("Message") }}</template>
        </form-editor>

        <template v-slot:footer>
            <div v-if="!!record_id && !!edited_record" class="text-center">
                <default-button
                    :class="{ 'mr-2': !edited_record.published_at }"
                    @click.prevent="close"
                >
                    <span v-if="!edited_record.published_at">{{ translate("Cancel") }}</span>
                    <span v-else>{{ translate("Close") }}</span>
                </default-button>
                <default-button
                    v-if="!edited_record.published_at"
                    color="primary"
                    class="ml-2"
                    @click.prevent="publish"
                >
                    {{ translate("Publish") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import is_record_editor from "@/nibnut/mixins/IsRecordEditor"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormEditor from "@/nibnut/components/Inputs/FormEditor"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import InstallationReport from "@/custom/components/InstallationReport/View"

export default {
    name: "InstallationReportEditor",
    mixins: [is_record_editor],
    components: {
        ModalDialog,
        FormEditor,
        DefaultButton,
        InstallationReport
    },
    watch: {
        installationReportId: "reload",
        show: "maybe_reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.installationReportId
        },
        maybe_reload () {
            if(this.show) this.reload()
        },
        close () {
            this.shown = false
        },
        publish () {
            this.save(this.$dayjs().format("YYYY-MM-DD HH:mm:ss"), "published_at").then(this.close)
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_reload () {
            return !!this.profile_id && !!this.show
        },
        cleanup_before_reload () {
            return false
        },
        reload_query () {
            return { relation_ids: this.relation_ids, fields: ["fieldset::editor", "ns::installation;fieldset::report-editor"] }
        },
        installation () {
            if(!this.edited_record) return {}
            return this.entity_record("installation", this.edited_record.installation_id)
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        installationReportId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "installation_report",
            entityName: "report:::reports",
            relation_ids: ["installation", "installation_log", "server_log"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#installation-report-editor {
    .stats {
        .card {
            height: 100%;
            .h2 {
                font-size: 3rem;
            }
        }
    }
}
</style>
