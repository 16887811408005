<template>
    <div v-if="!!social_providers.length">
        <div class="columns">
            <div
                v-for="social_provider in social_providers"
                :key="social_provider.id"
                :class="{[`col-${12 / social_providers.length}`]: true}"
                class="column mb-2"
            >
                <a :href="`/oauth/${social_provider.id}`" :class="{[`btn-${social_provider.id}`]: true}" class="btn btn-block">
                    <app-icon
                        :glyph="social_provider.glyph || social_provider.id"
                    /> {{ social_provider.name }}
                </a>
            </div>
        </div>
        <div class="divider text-center" data-content="OR"></div>
    </div>
</template>

<script>
import { mapState } from "vuex"

import AppIcon from "@/nibnut/components/AppIcon"

export default {
    components: {
        AppIcon
    },
    computed: {
        ...mapState(["app_context"]),
        social_providers () {
            return [
                { id: "facebook", name: "Facebook" },
                { id: "twitter", name: "Twitter" }
            ].filter(social_provider => {
                return !!this.app_context.settings[`${social_provider.id}_is_setup`]
            })
        }
    }
}
</script>
