import { Page } from "../components"

const is_page = {
    components: {
        Page
    },
    methods: {
        statused (status) {
            this.page_status = status
            this.waiting = false
        }
    },
    data () {
        return {
            page_status: 200,
            waiting: true
        }
    }
}

export default is_page
