<template>
    <modal-dialog
        id="subscribe-dialog"
        :show.sync="shown"
        :dismissable="true"
        class="subscribe-dialog"
    >
        <template v-slot:title>
            <span class="h5">
                <span v-if="!subscribed">{{ translate('Start subscription') }}</span>
                <span v-else-if="graced">{{ translate('Resume subscription') }}</span>
                <span v-else-if="mode === 'change'">{{ translate('Change subscription') }}</span>
                <span v-else-if="mode === 'cc'">{{ translate('Update credit card') }}</span>
                <span v-else>{{ translate('Start subscription') }}</span>
            </span>
        </template>

        <base-form
            :has-required-fields="true"
            @submit.prevent="subscribe"
        >
            <subscription-panel
                :price.sync="selected_price"
                :subscribed="subscribed"
                :coupon.sync="coupon"
                :mode="mode"
                @context-ready="setup_stripe_context"
            />
        </base-form>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :disabled="subscribing"
                    class="mr-2"
                    @click.prevent="shown = false"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    :waiting="subscribing"
                    :disabled="subscribing"
                    color="primary"
                    class="ml-2"
                    @click.prevent="subscribe"
                >
                    <span v-if="mode==='cc'">{{ translate('Change credit card') }}</span>
                    <span v-else-if="graced">{{ translate('Resume subscription{selected_price_price}', { selected_price_price: selected_price_price }) }}</span>
                    <span v-else-if="stick_to_it">{{ translate('Stick with current subscription') }}</span>
                    <span v-else>{{ translate('Start subscription{selected_price_price}', { selected_price_price: selected_price_price }) }}</span>
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script type="text/javascript">
import handles_subscriptions from "./HandlesSubscriptions"
import ui_utilities from "@/nibnut/mixins/UiUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import SubscriptionPanel from "./SubscriptionPanel"

export default {
    mixins: [handles_subscriptions, ui_utilities],
    components: {
        ModalDialog,
        BaseForm,
        DefaultButton,
        SubscriptionPanel
    },
    mounted () {
        this.reset_price()
    },
    watch: {
        show: "reset",
        subscriber: "reset_price"
    },
    methods: {
        reset () {
            this.coupon = null
        },
        reset_price () {
            const price = this.price
            this.selected_price = price ? { ...price } : null
        },
        subscribe () {
            if((this.mode === "cc") || this.graced || !this.stick_to_it) {
                const subscribe = (stripe_payment_method = null) => {
                    this.$store.dispatch("RECORD_ACTION", {
                        entity: "user",
                        id: this.subscriber.id,
                        action: "subscribe",
                        data: {
                            price_id: this.selected_price ? this.selected_price.id : 0,
                            stripe_payment_method,
                            coupon_code: this.coupon ? this.coupon.stripe_id : ""
                        },
                        method: "post"
                    }).then(() => {
                        this.shown = false
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.subscribing = false
                    })
                }

                this.subscribing = true
                if((this.mode === "change") && (!this.needs_cc || !this.stripe_price_price(this.selected_price, this.coupon, true))) subscribe()
                else {
                    this.get_stripe_payment_method().then(subscribe).catch(error => {
                        this.$error(error)
                    }).then(() => {
                        this.subscribing = false
                    })
                }
            }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        subscribed () {
            return !!this.subscription_status && !this.subscription_status.match(/^(cancelled|not-subscribed)$/i)
        },
        price () {
            if(!this.subscriber || !this.subscriber.current_subscription) return null
            return this.subscriber.current_subscription.price
        },
        subscription_status () {
            if(!this.subscriber || !this.subscriber.current_subscription) return ""
            return this.subscriber.current_subscription.status || ""
        },
        graced () {
            return this.subscription_status === "grace-period"
        },
        selected_price_price () {
            if(!this.selected_price || !this.selected_price.amount || !this.stripe_price_price(this.selected_price, this.coupon, true)) return ""
            return this.translate(" at {amount}", { amount: this.stripe_price_price(this.selected_price, this.coupon) })
        },
        stick_to_it () {
            const price = this.price
            if(!price || (this.subscription_status === "cancelled")) return false
            return this.selected_price.stripe_id === price.stripe_id
        },
        needs_cc () {
            if(this.mode === "cc") return true

            if(!this.subscribed) return !!this.selected_price && !!this.selected_price.amount

            return (!this.selected_price || (!!this.selected_price && !!this.selected_price.amount))
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        subscriber: {
            type: Object,
            required: true
        },
        mode: {
            validator: prop => !!prop && !!prop.match(/^(cc|subscribe|change|unsubscribe|resume|invite)$/),
            default: "change"
        }
    },
    data () {
        return {
            subscribing: false
        }
    }
}
</script>
