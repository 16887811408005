<template>
    <page
        id="dashboard"
        :title="translate('Dashboard')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h1 v-if="is_at_least_administrator">{{ translate("Dashboard") }}</h1>
            <span v-else>&nbsp;</span>
        </template>
        <admin-dashboard v-if="is_at_least_administrator" />
        <client-dashboard v-else />
    </page>
</template>

<script type="text/javascript">
import { is_page, profile_utilities } from "@/nibnut/mixins"

import AdminDashboard from "./AdminDashboard"
import ClientDashboard from "./ClientDashboard"

export default {
    mixins: [is_page, profile_utilities],
    components: {
        AdminDashboard,
        ClientDashboard
    }
}
</script>
