<template>
    <div v-if="installation" class="card installation-card">
        <div class="card-header">
            <div class="card-title h5">
                <span v-if="staging">{{ translate("Staging Area") }}</span>
                <span v-else>{{ translate("Live Installation") }}</span>
                <default-button
                    flavor="link"
                    :color="(clipboard_copy_status === null) ? 'light' : clipboard_button_color"
                    size="sm"
                    class="ml-2"
                    @click.prevent
                    v-clipboard:copy="installation.uuid"
                    v-clipboard:success="clipboard_copied"
                    v-clipboard:error="clipboard_error"
                >
                    {{ installation.uuid }}
                </default-button>
            </div>
        </div>
        <div class="card-body">
            <div
                v-if="!installation.id"
                class="empty"
            >
                <div class="empty-action">
                    <default-button
                        color="primary"
                        @click.prevent="$emit('create')"
                    >
                        {{ translate("Create...") }}
                    </default-button>
                </div>
            </div>
            <div v-else>
                <h6 class="d-flex">
                    <base-link
                        :href="href"
                        target="_blank"
                    >
                        {{ installation.name }}<app-icon glyph="external-link" class="ml-2" />
                    </base-link>
                    <base-link
                        :href="`https://forge.laravel.com/servers/${server.remote_id}/sites/${installation.remote_id}`"
                        target="nibnut-forge"
                        class="ml-8"
                    >
                        <img src="@/assets/img/forge-logo.svg" />
                    </base-link>
                    <base-link
                        v-if="!staging && !!installation.envoyer_id"
                        :href="`https://envoyer.io/projects/${installation.envoyer_id}`"
                        target="nibnut-envoyer"
                        class="ml-8"
                    >
                        <img src="@/assets/img/envoyer-logo.svg" />
                    </base-link>
                </h6>
                <div v-if="!!server.id" class="text-gray text-small">
                    {{ server.name }}&nbsp;&bull;&nbsp;{{ server.ip_address }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import {
    handles_clipboard
} from "@/nibnut/mixins"
import {
    DefaultButton,
    AppIcon,
    BaseLink
} from "@/nibnut/components"

export default {
    name: "Installation",
    mixins: [handles_clipboard],
    components: {
        DefaultButton,
        AppIcon,
        BaseLink
    },
    methods: {
        view_in_forge (row) {
            const node = document.getElementById(`installation-${row.id}`)
            if(node) node.click()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        server () {
            return this.entity_records("server").find(server => server.id === this.installation.server_id) || {}
        },
        href () {
            let url = this.installation.name
            if(!url.match(/^\/\//)) url = `//${url}`
            if(!url.match(/^https?:/)) url = `https:${url}`
            return url
        }
    },
    props: {
        installation: {
            type: Object,
            required: true
        },
        staging: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
        }
    }
}
</script>

<style lang="scss">
.installation-card {
    h6.d-flex {
        align-items: center;
        img {
            height: 0.9em;
            vertical-align: middle;
        }
    }
}
</style>
