<template>
    <div
        role="combobox"
        aria-haspopup="listbox"
        :aria-owns="`${id}-menu`"
        :aria-expanded="expanded"
        :class="{ 'autocomplete-oneline': !multiple }"
        class="form-autocomplete nibnut-select"
    >
        <div class="form-autocomplete-input form-input">
            <slot name="selection"></slot>

            <base-input
                ref="field"
                :id="id"
                type="text"
                :name="name"
                :value="query"
                :placeholder="placeholder"
                aria-autocomplete="nibnut-select"
                :aria-controls="`${id}-menu`"
                :disabled="disabled"
                :required="required"
                autocomplete="nibnut-select"
                @focus="maybe_expand"
                @touchstart="touchstart"
                @keyup="keyup"
                @keydown="keydown"
                @blur="blur"
            />
            <app-icon
                :glyph="glyph"
            />
        </div>

        <ul
            v-if="expanded"
            :id="`${id}-menu`"
            role="listbox"
            class="menu"
        >
            <li
                v-for="(option, index) in available_options"
                :key="option[idField]"
                role="option"
                tabindex="-1"
                class="menu-item"
            >
                <a
                    href
                    :class="{ disabled: !!option.disabled, active: ((active_choice_index < 0) && (value === option[idField])) || (active_choice_index === index) }"
                    @mousedown="pick(option)"
                >
                    <slot name="option">
                        <span v-html="highlighted_label(option)"></span>
                    </slot>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
// Inspired by https://24ways.org/2019/making-a-better-custom-select-element/
// ** using mousedown on options instead of click, so it doesn't interfere with menu's blur event (https://stackoverflow.com/questions/50313603/vuejs-on-blur-for-custom-select-element-not-working)
// Autofill rules: https://www.codementor.io/@leonardofaria/disabling-autofill-in-chrome-zec47xcui

import is_nibnut_component from "../../mixins/IsNibnutComponent"
import is_alpha_numerical_input from "../../mixins/IsAlphaNumericalInput"

import BaseInput from "./BaseInput"
import AppIcon from "../AppIcon"

export default {
    name: "BaseSelect",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        BaseInput,
        AppIcon
    },
    mounted () {
        this.reset_query()
        if(!this.query && this.value) {
            const selected_option = this.options.find(option => option[this.idField] === this.value)
            if(selected_option) this.query = selected_option[this.labelField]
        }
    },
    watch: {
        value: "reset_query",
        options: "reset_expanded_state"
    },
    methods: {
        reset_query () {
            if(!this.multiple) {
                const selected_option = this.options.find(option => option[this.idField] === this.value)
                if(selected_option) this.query = selected_option[this.labelField]
            }
        },
        reset_expanded_state () {
            if(!this.multiple && !!this.value && !!this.options.length) this.reset_query()
            if(document.activeElement === this.$refs.field.$el) this.set_expanded_state(this.queried)
        },
        set_expanded_state (expanded) {
            if(this.expanded !== expanded) {
                this.active_choice_index = -1
                if(!this.disabled) this.expanded = expanded
            }
        },
        maybe_expand (event) {
            event.target.select()
            if(this.expandOnFocus && !!this.options.length) this.expanded = true
        },
        keyup (event) {
            if(this.alphanumeric && this.iOS) this.touching = false

            let new_index
            switch (event.key) {
            case "ArrowDown":
                new_index = this.active_choice_index
                do {
                    new_index++
                    if(new_index >= this.options.length) new_index = 0
                } while(!!this.options[new_index].disabled && (new_index !== this.active_choice_index))
                this.active_choice_index = new_index
                break

            case "ArrowUp":
                new_index = this.active_choice_index
                do {
                    new_index--
                    if(new_index < 0) new_index = this.options.length - 1
                } while(!!this.options[new_index].disabled && (new_index !== this.active_choice_index))
                this.active_choice_index = new_index
                break

            case "Enter":
                if((this.active_choice_index >= 0) && !this.options[this.active_choice_index].disabled) {
                    this.pick(this.options[this.active_choice_index])
                    this.$refs.field.$el.blur()
                } else this.autopick()
                break

            case "Escape":
                this.$refs.field.$el.blur()
                break

            case "Shift":
            case "Tab":
            case "Control":
            case "Alt":
            case "Meta":
                break

            default:
                this.active_choice_index = -1
                this.query = event.target.value
                this.$emit("search", this.query)
                break
            }
        },
        autopick () {
            let option = null
            if(this.query) {
                option = this.options.find(option => {
                    return !option.disabled && (this.query.toLowerCase() === option[this.labelField].toLowerCase())
                })
            }
            if(!option) {
                option = { [this.idField]: this.emptyValue }
                if(!this.available_options.length || (typeof this.available_options[0][this.idField] === "undefined")) this.query = ""
            }
            this.pick(option)
            this.set_expanded_state(false)
        },
        blur () {
            if(this.alphanumeric && this.iOS) this.touching = false

            this.autopick()
        },
        pick (option) {
            if(!this.disabled && (!option || (typeof option[this.idField] !== "undefined"))) {
                this.$emit("input", option ? option[this.idField] : this.emptyValue, this.name, option)
                if(this.multiple) this.query = ""
            }
        },
        highlighted_label (option) {
            let label = ""
            if(option) label = option[this.labelField]
            if(label && this.query && !!option[this.idField]) {
                const length = this.query.length
                const index = label.toLowerCase().indexOf(this.query.toLowerCase())
                if(index >= 0) label = `${label.substring(0, index)}<strong class="text-primary">${label.substring(index, index + length)}</strong>${label.substring(index + length)}`
            }
            return label
        }
    },
    computed: {
        queried () {
            return !!this.query.length
        },
        selected_value () {
            if(this.value === this.emptyValue) return this.required ? "" : this.emptyLabel
            const option = this.options.find(option => option[this.idField] === this.value)
            return option ? option[this.labelField] : this.emptyLabel
        },
        placeholder () {
            if(this.required && (this.value === this.emptyValue)) return this.emptyLabel
            return ""
        },
        available_options () {
            const options = this.options.slice()
            if(!this.required && !!this.emptyLabel) options.unshift({ [this.idField]: this.emptyValue, [this.labelField]: this.emptyLabel })

            if(!options.length && this.queried) {
                options.push({ [this.labelField]: `(${this.translate("No results found...")})`, disabled: true })
            }

            return options
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        glyph: {
            type: String,
            default: "chevron-down"
        },
        expandOnFocus: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            query: "",
            expanded: false,
            active_choice_index: -1
        }
    }
}
</script>

<style lang="scss">
@import "../../../assets/sass/variables";

.form-autocomplete.nibnut-select {
    .form-autocomplete-input {
        padding-top: 3px;
        padding-bottom: 3px;

        & > input {
            padding-right: $unit-5;
        }

        & > i.las {
            position: absolute;
            right: $control-padding-x;
            bottom: $control-padding-y * 2;
            pointer-events: none;
        }
    }
    .menu {
        .menu-item {
            a.disabled {
                &, &:hover, &:visited, &:focus {
                    background: transparent;
                    color: $gray-color;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }
}
</style>
