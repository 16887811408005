<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Contacts") }}
                <default-button
                    flavor="link"
                    class="ml-2"
                    @click.prevent="synchronize"
                >
                    <app-icon glyph="refresh-cw" :spin="syncing" />
                </default-button>
            </div>
        </div>
        <div class="card-body">
            <data-table
                id="contacts-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :searchable="false"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                @sort="sort_by"
                @page="goto_page"
                @add="add_row"
                @click="edit_row"
            >
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === 'name'"
                    >
                        <contact-access-button
                            :contact="row"
                            size="sm"
                            @click.prevent.stop="maybe_toggle_contact_access(row)"
                        />
                        {{ row.first_name }} {{ row.last_name }}
                    </div>
                    <div
                        v-else-if="field === '_buttons'"
                        class="text-right"
                    >
                        <contact-report-recipient-button
                            :contact="row"
                            size="sm"
                            @click.prevent.stop="toggle_contact_report_recipient(row)"
                        />
                        <harvest-button
                            main-endpoint="clients"
                            :main-id="clientRemoteId"
                            sub-endpoint="contacts"
                            :sub-id="row.remote_id"
                            class="ml-2"
                            @click.native.stop
                        />
                        <mailto-link
                            :email="row.email"
                            class="btn btn-link ml-2"
                            @click.native.stop
                        >
                            <app-icon glyph="at-sign" />
                        </mailto-link>
                    </div>
                    <span
                        v-else
                    >
                        {{ row[field] }}
                    </span>
                </template>
            </data-table>

            <contact-editor
                :show.sync="editing"
                :contact-id="edited_row.id || 0"
                :client-id="clientId"
            />

            <invite-user-dialog
                :show.sync="inviting"
                :invitation-data="invitation_data"
                :client-id="invitation_client_id"
                @invited="set_contact_access($event.id)"
            />
        </div>
    </div>
</template>

<script>
import { is_data_table_source } from "@/nibnut/mixins"
import { handles_contact_access } from "@/custom/mixins"

import {
    MailtoLink,
    DefaultButton,
    AppIcon
} from "@/nibnut/components"
import {
    HarvestButton
} from "@/custom/components"
import {
    ContactEditor
} from "@/custom/dialogs"

export default {
    name: "Contacts",
    mixins: [is_data_table_source, handles_contact_access],
    components: {
        MailtoLink,
        DefaultButton,
        AppIcon,
        HarvestButton,
        ContactEditor
    },
    watch: {
        contacts: "refresh"
    },
    methods: {
        synchronize () {
            this.syncing = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "contact",
                    action: "records/synchronize",
                    data: {
                        client_id: this.clientId,
                        client_remote_id: this.clientRemoteId
                    }
                }
            ).then(() => {
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.syncing = false
            })
        },
        edit_row (row) {
            this.edited_row = row
            this.editing = true
        },
        add_row () {
            this.edit_row({ id: 0 })
        }
    },
    computed: {
        state_identifier () {
            return `client-${this.clientId}-contacts`
        },
        rows () {
            return this.contacts
        }
    },
    props: {
        clientRemoteId: {
            type: Number,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        },
        contacts: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            syncing: false,
            editing: false,
            edited_row: {}
        }
    }
}
</script>
