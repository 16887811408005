import orderBy from "lodash/orderBy"

import profile_utilities from "./ProfileUtilities"
import handles_stored_state from "./HandlesStoredState"

import DataTable from "@/nibnut/components/DataTable/DataTable"

const is_data_table_source = {
    mixins: [profile_utilities, handles_stored_state],
    components: {
        DataTable
    },
    created () {
        this.reset_state()
    },
    mounted () {
        this.reset() // reset state && reload data
    },
    watch: {
        profile_id: "reset",
        $route: "reset"
    },
    methods: {
        refresh () {
            this.set_state_values({
                total: this.rows.length,
                found: this.filtered ? this.filtered_rows.length : this.rows.length
            })
        },
        reset () {
            this.refresh()
        },
        sort_by (field, sort) {
            const columns = this.columns[field] ? this.columns : this.visible_columns
            const column = columns[field]
            if(!column || (column.sort === false)) return // false is not sortable

            Object.keys(columns).forEach(column_id => {
                if(column_id === field) columns[column_id].sort = sort || "asc"
                else if(columns[column_id].sort !== false) columns[column_id].sort = null
            })

            this.set_state_values({
                sort_by: field,
                sort_dir: column.sort
            })
            this.refresh()
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        },
        filter (filter_on, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                filter_on: filter_on || null,
                filter
            })
            this.refresh()
        },
        search (search) {
            if(!!search && (search.length < this.min_search_length)) return
            this.filtered_by_selection = false

            this.set_state_values({
                page: 1,
                search
            })
            this.refresh()
        },
        toggle_archived () {
            this.set_state_value("archived", !this.state.archived)
            this.refresh()
        },
        goto_page (page) {
            if((this.state.page === page) || (page <= 0) || (page > this.nb_pages)) return
            this.set_state_value("page", page)
            this.refresh()
        },
        page_rows (rows) {
            if(!this.state.per_page) return rows

            const start = this.state.per_page * (this.state.page - 1)
            return rows.slice(start, start + this.state.per_page)
        },
        sort_rows (rows) {
            if(this.state.sort_by) {
                return orderBy(rows, (row) => {
                    if(!row[this.state.sort_by]) {
                        if(this.state.sort_dir === "asc") return "999999999"
                        return "000000000"
                    }
                    return row[this.state.sort_by]
                }, this.state.sort_dir)
            }
            return rows
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            if(this.state.filter_on && !this.state.filter_on.match(/^_/) && this.state.filter) {
                return rows.filter((row) => {
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        search_rows (rows) {
            const search = this.state.search.toLowerCase()
            if(search.length >= this.min_search_length) {
                return this.rows.filter((row) => {
                    return Object.keys(row).some((key) => {
                        return String(row[key]).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return rows
        },
        select_row (identifier, select) {
            const index = this.selected_row_ids.indexOf(identifier)
            if(select && (index < 0)) this.selected_row_ids.push(identifier)
            else if(!select && (index >= 0)) this.selected_row_ids.splice(index, 1)
        },
        filter_by_selection () {
            this.filtered_by_selection = true
            this.reset_state(true)
            this.set_state_value("page", 1)
            this.refresh()
        },
        clear_selection () {
            this.filtered_by_selection = false
            this.selected_row_ids = []
            this.reset_state(true)
            this.set_state_value("page", 1)
            this.refresh()
        }
    },
    computed: {
        filtered () {
            return !!this.state.filter || !!this.state.search || this.filtered_by_selection
        },
        fields () {
            return ["fieldset::list"]
        },
        visible_columns () {
            return this.columns
        },
        rows () {
            return []
        },
        filtered_rows () {
            let rows = this.rows.slice()

            if(!this.state.archived && !this.filtered_by_selection) rows = rows.filter(record => !record.deleted_at)

            rows = this.sort_rows(rows)
            rows = this.filter_rows(rows)
            if(!this.filtered_by_selection) rows = this.search_rows(rows)

            return this.page_rows(rows)
        },
        nb_pages () {
            if(!this.state.per_page) return 1
            return Math.ceil(this.state.found / this.state.per_page)
        }
    },
    data () {
        return {
            loading: false,
            min_search_length: 3,

            // Here we define *all* possible columns for the data table ; they will be filtered out as required in the "columns" computed prop
            columns: {
                /* id: { label: "#", sort: null, type: "numeric" } */
            },

            default_state: {
                per_page: 10,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            selected_row_ids: null,
            filtered_by_selection: false
        }
    }
}

export default is_data_table_source
