<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Web Apps") }}
            </div>
        </div>
        <div class="card-body">
            <data-table
                id="web-apps-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :searchable="false"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                @sort="sort_by"
                @page="goto_page"
                @add="record_create('web_app')"
                @click="record_edit('web_app', $event.id)"
            >
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === 'name'"
                    >
                        {{ row.name }}
                    </div>
                    <span
                        v-else
                    >
                        {{ row[field] }}
                    </span>
                </template>
            </data-table>

            <modal-dialog
                v-if="!!client"
                id="new-product-dialog"
                :show.sync="editing"
            >
                <template v-slot:title><span class="h5">{{ translate("New Product for {name}", { name: client.name }) }}</span></template>
                <base-form
                    :has-required-fields="true"
                    @submit.prevent="record_save"
                >
                    <div class="columns">
                        <div class="column col-9 col-md-8 col-sm-7">
                            <form-input
                                id="name"
                                name="name"
                                v-model="edited_row.name"
                                :required="true"
                                :error="has_error('name')"
                            >
                                <template v-slot:label>{{ translate("Name") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-3 col-md-4 col-sm-5">
                            <form-input
                                id="code_prefix"
                                name="code_prefix"
                                v-model="edited_row.code_prefix"
                                :required="true"
                                :error="has_error('code_prefix')"
                            >
                                <template v-slot:label>{{ translate("Project Prefix") }}</template>
                            </form-input>
                        </div>
                    </div>
                </base-form>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            :disabled="creating"
                            class="mr-2"
                            @click.prevent="editing = false"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                        <default-button
                            :waiting="creating"
                            :disabled="creating"
                            color="primary"
                            class="ml-2"
                            @click.prevent="record_save"
                        >
                            {{ translate("Create") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>
            <confirmation
                v-if="confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source, crud_utilities, handles_errors, confirms } from "@/nibnut/mixins"

import {
    DefaultButton,
    ModalDialog,
    BaseForm,
    FormInput
} from "@/nibnut/components"

export default {
    name: "WebApps",
    mixins: [is_data_table_source, crud_utilities, handles_errors, confirms],
    components: {
        DefaultButton,
        ModalDialog,
        BaseForm,
        FormInput
    },
    watch: {
        webApps: "refresh"
    },
    methods: {
        record_create (entity) {
            this.record_shell(entity).then(shell_record => {
                this.edited_row = shell_record
                this.edited_row.client_id = this.clientId
                this.editing = true
            })
        },
        record_save () {
            this.creating = true
            this.$store.dispatch("CREATE_RECORD", {
                entity: "web_app",
                data: this.edited_row
            }).then(record => {
                this.editing = false
                this.edited_row = {}
                this.$router.push({ name: "web_app.edit", params: { id: record.id } })
            }).catch(this.receive_error).then(record => {
                this.creating = false
            })
        },
        row_confirm_delete (row) {
            this.edited_row = row
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: row.name }),
                    message: this.translate("Do you really want to delete this product? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.row_delete()
            else this.done_confirming()
        },
        row_delete () {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: "web_app",
                    id: this.edited_row.id
                }
            ).then(() => {
                this.done_confirming()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.edited_row = {}
            })
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return `client-${this.clientId}-web-apps`
        },
        rows () {
            return this.webApps
        },
        client () {
            return this.entity_record("client", this.clientId)
        }
    },
    props: {
        clientId: {
            type: Number,
            required: true
        },
        webApps: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            syncing: false,
            editing: false,
            edited_row: {},
            creating: false
        }
    }
}
</script>
