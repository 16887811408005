import string_utilities from "@/nibnut/mixins/StringUtilities"

const subscription_string_utilities = {
    mixins: [string_utilities],
    methods: {
        discount_conditions (coupon, price) {
            if(!coupon) return ""
            if(coupon.duration === "once") return this.translate("First month:::First {count} months, then {regular_price}", { count: 1, regular_price: this.stripe_price_regular_price(price) }, 1)
            if(coupon.duration === "repeating") return this.translate("First month:::First {count} months, then {regular_price}", { count: coupon.duration_in_months, regular_price: this.stripe_price_regular_price(price) }, coupon.duration_in_months)
            return ""
        }
    }
}

export default subscription_string_utilities
