<template>
    <default-button
        v-if="!!contact && !!contact.id && !!contact.user_id"
        flavor="link"
        :color="contact.is_report_recipient ? 'primary' : 'light'"
        :title="translate('Make contact report recipient or not')"
        v-bind="button_props"
        v-on="$listeners"
    >
        <app-icon glyph="flag" />
    </default-button>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    name: "ContactReportRecipientButton",
    components: {
        DefaultButton,
        AppIcon
    },
    computed: {
        button_props () {
            const { color, flavor, title, contact, ...button_props } = this.$props
            return button_props
        }
    },
    props: {
        contact: {
            type: Object,
            required: true
        },
        ...DefaultButton.props
    }
}
</script>
