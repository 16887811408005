<template>
    <input-group>
        <template v-slot:right_addon>
            <default-button
                @click.prevent="peeking=!peeking"
                class="input-group-btn"
            >
                <app-icon :glyph="glyph" />
            </default-button>
        </template>
        <base-input
            ref="field"
            :id="identifier"
            :type="standardized_type"
            :name="name"
            :value="value"
            :disabled="disabled"
            :required="required && touched"
            @focus="touched = true"
            @touchstart="touchstart"
            @keyup="$emit('keyup', $event)"
            @keydown="keydown"
            @blur="blur"
            @change="changed"
        />
    </input-group>
</template>

<script>
import is_nibnut_component from "../../mixins/IsNibnutComponent"
import is_alpha_numerical_input from "../../mixins/IsAlphaNumericalInput"

import InputGroup from "./InputGroup"
import DefaultButton from "../Buttons/DefaultButton"
import BaseInput from "./BaseInput"
import AppIcon from "../AppIcon"

export default {
    name: "PasswordField",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        InputGroup,
        DefaultButton,
        BaseInput,
        AppIcon
    },
    methods: {
        changed (event) {
            this.$emit("input", event.target.value, this.name)
        }
    },
    computed: {
        glyph () {
            if(this.peeking) return "eye-slash"
            return "eye"
        },
        standardized_type () {
            if(this.peeking) return "text"
            return "password"
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            touched: false,
            peeking: false
        }
    }
}
</script>
