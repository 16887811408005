<template>
    <page
        id="settings"
        :title="translate('Settings')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <div v-if="!!edited_record" class="columns">
            <div class="column col-3 col-md-4 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Notification Delays") }}</div>
                    </div>
                    <div class="card-body">
                        <form-input
                            id="alert_last_monitored"
                            name="alert_last_monitored"
                            v-model="edited_record.alert_last_monitored"
                            type="number"
                            :min="1"
                            :required="true"
                            :saving="saving('alert_last_monitored')"
                            :error="has_error('alert_last_monitored')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Installation not monitored") }}</template>
                            <template v-slot:right_addon>
                                <span class="input-group-addon">{{ translate("day(s)") }}</span>
                            </template>
                        </form-input>
                    </div>
                </div>
            </div>
            <div class="column col-3 col-md-4 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Harvest Settings") }}</div>
                    </div>
                    <div class="card-body">
                        <form-input
                            id="harvest_budget_notification_percentage"
                            name="harvest_budget_notification_percentage"
                            v-model="edited_record.harvest_budget_notification_percentage"
                            type="float"
                            :step="0.1"
                            :min="0"
                            :max="100"
                            :required="true"
                            :saving="saving('harvest_budget_notification_percentage')"
                            :error="has_error('harvest_budget_notification_percentage')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Notify when budget reaches") }}</template>
                            <template v-slot:right_addon>
                                <span class="input-group-addon">%</span>
                            </template>
                        </form-input>
                    </div>
                </div>
            </div>
            <div class="column col-3 col-md-4 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Forge Settings") }}</div>
                    </div>
                    <div class="card-body">
                        <form-dropdown
                            id="forge_staging_server_id"
                            name="forge_staging_server_id"
                            v-model="edited_record.forge_staging_server_id"
                            :empty-value="0"
                            :options="servers"
                            :show-all="true"
                            :required="false"
                            id-field="remote_id"
                            :saving="saving('forge_staging_server_id')"
                            :error="has_error('forge_staging_server_id')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Staging Server") }}</template>
                        </form-dropdown>
                    </div>
                </div>
            </div>
            <div class="column col-3 col-md-4 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("User Management") }}</div>
                    </div>
                    <div class="card-body">
                        <invitation-expiries
                            id="default_invitation_delay"
                            name="default_invitation_delay"
                            v-model="edited_record.default_invitation_delay"
                            :required="true"
                            :saving="saving('default_invitation_delay')"
                            :error="has_error('default_invitation_delay')"
                            @input="save"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!!edited_record && is_developer" class="card">
            <div class="card-header">
                <div class="card-title h5">{{ translate("Developer Zone") }}</div>
            </div>
            <div class="card-body">
                <form-toggle-input
                    id="bug_reporter_active"
                    name="bug_reporter_active"
                    type="checkbox"
                    v-model="edited_record.bug_reporter_active"
                    :true-value="1"
                    :false-value="0"
                    :required="false"
                    :saving="saving('bug_reporter_active')"
                    :error="has_error('bug_reporter_active')"
                    @input="save"
                >
                    {{ translate('Bug Reporter') }}
                </form-toggle-input>
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { is_record_editor_page } from "../nibnut/mixins"

import { FormInput, FormDropdown, InvitationExpiries, FormToggleInput } from "../nibnut/components"

export default {
    mixins: [is_record_editor_page],
    components: {
        FormInput,
        FormDropdown,
        InvitationExpiries,
        FormToggleInput
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        reload_query () {
            return { relation_ids: this.relation_ids, fields: ["ns::server;fieldset::picker"] }
        },
        servers () {
            return orderBy(this.entity_records("server"), "name", "asc")
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings",
            relation_ids: ["server"]
        }
    }
}
</script>
