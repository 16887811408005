<template>
    <modal-dialog
        id="new-installation-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <div v-if="staging" class="h5">{{ translate("Staging Installation") }}</div>
            <div v-else class="h5">{{ translate("Live Installation") }}</div>
        </template>

        <loader
            v-if="!edited_record"
            class="my-8 col-mx-auto"
        />

        <form-dropdown
            v-if="!!edited_record && !staging"
            id="envoyer_id"
            name="envoyer_id"
            v-model="edited_record.envoyer_id"
            :options="envoyer_projects"
            :show-all="true"
            :required="true"
            :error="has_error('envoyer_id')"
        >
            <template v-slot:label>{{ translate("Envoyer Project") }}</template>
        </form-dropdown>

        <form-dropdown
            v-if="!!edited_record"
            id="server_remote_id"
            name="server_remote_id"
            v-model="edited_record.server_remote_id"
            :options="available_servers"
            :show-all="true"
            :required="true"
            :disabled="!!edited_record.envoyer_id || (available_servers.length <= 1)"
            :error="has_error('server_remote_id')"
        >
            <template v-slot:label>{{ translate("Server") }}</template>
        </form-dropdown>

        <form-dropdown
            v-if="!!edited_record && !!edited_record.server_remote_id"
            id="remote_id"
            name="remote_id"
            v-model="edited_record.remote_id"
            :options="forge_sites"
            :show-all="true"
            :required="true"
            :error="has_error('remote_id')"
        >
            <template v-slot:label>{{ translate("Installation") }}</template>
        </form-dropdown>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :disabled="creating"
                    class="mr-2"
                    @click.prevent="close"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    :waiting="creating"
                    :disabled="creating"
                    class="ml-2"
                    @click.prevent="create"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import Loader from "@/custom/components/Loader"

export default {
    name: "NewInstallationDialog",
    mixins: [handles_saving, misc_utilities],
    components: {
        ModalDialog,
        FormDropdown,
        DefaultButton,
        Loader
    },
    watch: {
        show: "reset",
        "edited_record.envoyer_id": "auto_pick_server",
        "edited_record.server_remote_id": "auto_pick_site"
    },
    methods: {
        reset () {
            if(this.show) {
                this.envoyer_projects = []
                this.forge_servers = []
                this.forge_sites = []
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: "app",
                        action: "new-installation-choices",
                        method: "get",
                        passthru: true
                    }
                ).then(results => {
                    this.envoyer_projects = results.envoyer_projects
                    this.forge_servers = results.forge_servers

                    this.$store.dispatch(
                        "FETCH_RECORD_SHELL",
                        { entity: "installation" }
                    ).then(record => {
                        this.edited_record = {
                            ...record,
                            server_remote_id: 0
                        }
                        this.edited_record.web_app_id = this.webApp.id
                        this.edited_record.is_stage = this.staging

                        if(this.edited_record.is_stage) {
                            const default_stage_server_remote_id = this.constants("default_stage_server_id") // Forge Server ID
                            const default_stage_server = this.forge_servers.find(forge_server => forge_server.id === default_stage_server_remote_id)
                            this.edited_record.server_remote_id = default_stage_server ? default_stage_server.id : 0
                        } else {
                            this.edited_record.envoyer_id = this.envoyer_projects[0].id
                        }
                    }).catch(error => {
                        this.$error(error.message)
                    })
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        auto_pick_server () {
            if(this.edited_record.envoyer_id) {
                const envoyer_project = this.envoyer_projects.find(envoyer_project => envoyer_project.id === this.edited_record.envoyer_id)
                if(!envoyer_project) this.edited_record.envoyer_id = 0
                else {
                    const available_servers = this.available_servers
                    this.edited_record.server_remote_id = available_servers.length ? available_servers[0].id : 0
                }
            }
        },
        auto_pick_site () {
            this.forge_sites = []
            if(this.edited_record.server_remote_id) {
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: "app",
                        action: `new-installation-choices/${this.edited_record.server_remote_id}`,
                        method: "get",
                        passthru: true
                    }
                ).then(results => {
                    this.forge_sites = results.forge_sites
                    this.edited_record.remote_id = this.forge_sites.length ? this.forge_sites[0].id : 0
                    this.edited_record.name = this.forge_sites.length ? this.forge_sites[0].name : null
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        create () {
            this.creating = true
            this.save_data_for_record_id(
                "installation",
                0,
                {
                    ...this.edited_record,
                    relation_ids: ["server"]
                }
            ).then(() => {
                if(!this.has_error()) this.close()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.creating = false
            })
        },
        close () {
            this.shown = false
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        envoyer_project () {
            return this.envoyer_projects.find(envoyer_project => envoyer_project.id === this.edited_record.envoyer_id)
        },
        available_servers () {
            const envoyer_project = this.envoyer_project
            if(!envoyer_project) return this.forge_servers
            return this.forge_servers.filter(forge_server => {
                return envoyer_project.forge_server_ids.indexOf(forge_server.id) >= 0
            })
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        webApp: {
            type: Object,
            default () {
                return {}
            }
        },
        staging: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            envoyer_projects: [],
            forge_servers: [],
            forge_sites: [],
            edited_record: null,
            creating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";
</style>
