import { mapGetters, mapState } from "vuex"

import misc_utilities from "./MiscUtilities"

const profile_utilities = {
    mixins: [misc_utilities],
    methods: {
        role_is_administrator (role) {
            return (role === this.constants("roles", "ROLE_ADMIN").id)
        },
        role_is_developer (role) {
            return (role === this.constants("roles", "ROLE_DEVELOPER").id)
        },
        role_is_at_least_administrator (role) {
            return (role >= this.constants("roles", "ROLE_ADMIN").id)
        }
    },
    computed: {
        ...mapState(["profile_id"]),
        ...mapGetters(["entity_records"]),
        raw_user_records () {
            return this.entity_records("user")
        },
        profile () {
            return this.raw_user_records.find(user => user.id === this.profile_id) || {}
        },
        is_at_least_administrator () {
            return this.is_administrator || this.is_developer
        },
        is_administrator () {
            if(!this.profile_id) return false
            return this.role_is_administrator(this.profile.role)
        },
        is_developer () {
            if(!this.profile_id) return false
            return this.role_is_developer(this.profile.role)
        },
        is_app_user () {
            return !!this.$cookie.get("nibnut-app-user")
        }
    }
}

export default profile_utilities
