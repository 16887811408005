<template>
    <input
        v-bind="$props"
        v-on="$listeners"
        class="form-input"
    />
</template>

<script>
export default {
    name: "BaseInput",
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(text|number|password|email)$/i),
            default: "text"
        },
        required: {
            type: Boolean,
            required: true
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        maxlength: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
