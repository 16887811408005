<template>
    <div>
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <maintenance />
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import Maintenance from "./Maintenance"

export default {
    components: {
        Maintenance
    }
}
</script>
