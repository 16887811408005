<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <div class="modal-header">
            <dismiss-link
                v-if="dismissable"
                id="first_focusable"
                @click="dismiss"
                class="btn btn-clear float-right"
            />
            <div class="modal-title h5">
                <span v-if="fetching">{{ translate("Fetching your invitation...") }}</span>
                <span v-else>{{ translate("Signup") }}</span>
            </div>
        </div>
        <div class="modal-body">
            <div
                v-if="fetching"
                class="loader-container"
            >
                <loader />
            </div>
            <base-form
                v-else
                :has-required-fields="true"
                @submit.prevent.stop="signup"
            >
                <form-input
                    :id="dismissable ? 'email' : 'first_focusable'"
                    ref="first_field"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    :maybe-autofilled="true"
                    @input="update_email"
                    @blur="update_email"
                >
                    <template v-slot:label v-if="!labelLess">{{ translate("Email") }}</template>
                </form-input>
                <password-editor
                    id="password"
                    v-model="password"
                    type="password"
                    name="password"
                    :error="has_error('password')"
                    :required="true"
                >
                    <template v-slot:label v-if="!labelLess">{{ translate("Password") }}</template>
                </password-editor>

                <div class="columns">
                    <div class="column col-6 col-sm-12 mb-2">
                        <form-input
                            id="first_name"
                            v-model="first_name"
                            name="first_name"
                            :error="has_error('first_name')"
                            :required="false"
                        >
                            <template v-slot:label v-if="!labelLess">{{ translate("First Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-sm-12 mb-2">
                        <form-input
                            id="last_name"
                            v-model="last_name"
                            name="last_name"
                            :error="has_error('last_name')"
                            :required="false"
                        >
                            <template v-slot:label v-if="!labelLess">{{ translate("Last Name") }}</template>
                        </form-input>
                    </div>
                </div>
                <subscription-panel
                    :price.sync="selected_price"
                    :subscribed="false"
                    :coupon.sync="coupon"
                    :default-price-id="default_price_id"
                    :trial-period="trial_period"
                    mode="subscribe"
                    @context-ready="setup_stripe_context"
                />

                <div class="text-center mt-8">
                    <feedback-button
                        type="submit"
                        :status="feedback_status"
                        :waiting="loading"
                        :single-use="false"
                        color="primary"
                    >
                        <template v-slot:message>
                            <div
                                :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                            >
                                <div v-if="!!already_invited" class="toast toast-error text-center">
                                    {{ already_invited }}
                                    <br />
                                    <small><a href @click.prevent="resend_invitation">({{ translate("Please resend me my invitation link...") }})</a></small>
                                </div>
                                <span v-else>{{ feedback_message }}</span>
                            </div>
                        </template>
                        {{ translate("Sign Up!") }}
                    </feedback-button>
                </div>
            </base-form>
        </div>
        <div class="modal-footer">
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="goto('login')"
            >
                {{ translate("I'm already signed-up - let me login") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "@/nibnut/dialogs/Authentication/IsPanel"

import { Loader } from "@/custom/components"
import { DismissLink, BaseForm, FormInput, PasswordEditor, FeedbackButton, DefaultButton } from "@/nibnut/components"
import { SubscriptionPanel, handles_subscriptions } from "@/nibnut/Stripe"

export default {
    mixins: [is_panel, handles_subscriptions],
    components: {
        DismissLink,
        BaseForm,
        FormInput,
        PasswordEditor,
        FeedbackButton,
        DefaultButton,
        Loader,
        SubscriptionPanel
    },
    mounted () {
        this.load_invitation()
    },
    methods: {
        load_invitation () {
            if(window.location.search) {
                const matches = window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)
                if(matches) {
                    const token = matches[1]

                    this.fetching = true
                    this.$store.dispatch("LOAD_INVITATION", {
                        token
                    }).then(invitation_data => {
                        this.invitation_token = token
                        this.first_name = invitation_data.first_name
                        this.last_name = invitation_data.last_name
                        this.email = invitation_data.email
                        this.zip = invitation_data.zip
                        this.country = invitation_data.country
                        this.default_price_id = invitation_data.price_id || 0
                        this.trial_period = invitation_data.trial_period || 0
                        this.coupon = invitation_data.coupon_code ? { stripe_id: invitation_data.coupon_code } : null
                    }).catch(this.receive_error).then(() => {
                        this.fetching = false
                    })
                }
            }
        },
        resend_invitation () {
            if(this.email) {
                /*
                this.$store.dispatch("RECORD_ACTION", { entity: "user", action: "re-invite", data: { email: this.email }, passthru: true }).then(() => {
                    this.$success("<strong>" + this.translate("Invitation link re-sent!") + "</strong><br>" + this.translate("Check your inbox... (or junk folder, just in case)"))
                }).catch(error => {
                    this.$error("<strong>" + error.header + "</strong><br>" + error.message)
                })
                */
            }
        },
        signup () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                const subscribe = (stripe_payment_method = null) => {
                    this.$store.dispatch("SIGNUP", {
                        data: {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            password: encodeURIComponent(this.password),
                            price_id: this.selected_price ? this.selected_price.id : 0,
                            stripe_payment_method,
                            coupon_code: this.coupon ? this.coupon.stripe_id : null,
                            invitation_token: this.invitation_token
                        }
                    }).then(() => {
                        this.$emit("input", "")
                        this.first_name = ""
                        this.last_name = ""
                        this.password = ""
                        this.zip = null
                        this.country = null
                        this.default_price_id = 0
                        this.trial_period = 0
                        this.selected_price = null
                        this.coupon = null
                        this.invitation_token = ""
                        this.$store.dispatch("UNREQUEST_LOGIN")
                        window.location.search = ""
                    }).catch(error => {
                        if(error) {
                            if(error.topic_id && (error.topic_id === "invitation_token")) {
                                this.already_invited = error.message
                            } else this.receive_error(error)
                        }
                    }).then(() => {
                        this.loading = false
                    })
                }
                this.get_stripe_payment_method(this.zip, this.country).then(subscribe).catch(error => {
                    this.$error(error)
                }).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    data () {
        return {
            fetching: false,
            invitation_token: "",
            first_name: "",
            last_name: "",
            password: "",
            zip: null,
            country: null,
            trial_period: 0
        }
    }
}
</script>
