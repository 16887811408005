<template>
    <page
        id="product-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <template v-slot:title><h2>{{ translate("Web Application") }}</h2></template>
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
        >
            <div v-if="!!edited_record" class="columns mt-8">
                <div
                    class="column col-7 col-sm-12"
                >
                    <div class="columns">
                        <div class="column col-8">
                            <form-input
                                id="name"
                                name="name"
                                v-model="edited_record.name"
                                :required="true"
                                :saving="saving('name')"
                                :error="has_error('name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Name") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-4">
                            <form-input
                                id="code_prefix"
                                name="code_prefix"
                                v-model="edited_record.code_prefix"
                                :required="true"
                                :saving="saving('code_prefix')"
                                :error="has_error('code_prefix')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Project Prefix") }}</template>
                            </form-input>
                        </div>
                    </div>

                    <installation
                        :installation="stage_installation"
                        :staging="true"
                        class="mt-4"
                        @create="new_installation(true)"
                    />
                    <installation
                        :installation="live_installation"
                        :staging="false"
                        class="mt-4"
                        @create="new_installation(false)"
                    />
                </div>
                <div
                    class="column col-5 col-sm-12"
                >
                    <projects
                        :web-app="edited_record"
                        :projects="projects"
                    />

                    <subscription-card
                        :record="edited_record"
                        :editable="true"
                        class="mt-4"
                    />
                </div>
            </div>
        </base-form>

        <div
            class="text-center mt-8"
        >
            <default-button
                flavor="link"
                color="error"
                size="sm"
                @click.prevent="confirm_record_delete"
            >
                {{ translate("Delete Product") }}
            </default-button>
        </div>

        <new-installation-dialog
            :show.sync="setting_up"
            :web-app="edited_record"
            :staging="setup_is_staging"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"

import {
    BaseForm,
    FormInput,
    DefaultButton
} from "@/nibnut/components"
import { SubscriptionCard } from "@/nibnut/Stripe"
import Projects from "./Projects"
import Installation from "./Installation"
import NewInstallationDialog from "./NewInstallationDialog"

export default {
    mixins: [is_record_editor_page],
    components: {
        BaseForm,
        FormInput,
        DefaultButton,
        SubscriptionCard,
        Projects,
        Installation,
        NewInstallationDialog
    },
    methods: {
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.translate("Do you really want to delete this product? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted (record) {
            this.$router.push({ name: "client.edit", params: { id: record.client_id } })
        },
        installation_by_type (staging = false) {
            if(!this.edited_record || !this.edited_record.id) return {}
            return this.entity_records("installation").find(installation => {
                return (installation.web_app_id === this.edited_record.id) && (installation.is_stage === staging)
            }) || {}
        },
        new_installation (staging) {
            if(this.edited_record && this.edited_record.id) {
                this.setup_is_staging = staging
                this.setting_up = true
            }
        }
    },
    computed: {
        default_back () {
            return { title: this.translate("Client"), href: { name: "client.edit", params: { id: this.edited_record.client_id } } }
        },
        projects () {
            if(!this.edited_record || !this.edited_record.id) return []
            return this.entity_records("project").filter(project => project.web_app_id === this.edited_record.id)
        },
        stage_installation () {
            return this.installation_by_type(true)
        },
        live_installation () {
            return this.installation_by_type(false)
        }
    },
    data () {
        return {
            entity: "web_app",
            entityName: "Web App:::Web Apps",
            relation_ids: ["project", "installation", "server"],

            setup_is_staging: true,
            setting_up: false
        }
    }
}
</script>
