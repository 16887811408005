<template>
    <th
        @mouseover="hovered=true"
        @mouseout="hovered=false"
        @click="click"
    >
        {{ label }}
        <app-icon
            v-if="sort !== false"
            :glyph="glyph"
            :class="{ 'active': !!sort, 'd-invisible': !sort && !hovered, 'text-gray': !sort && !hovered, 'ml-4': !compact }"
            class="print-hide"
        />
    </th>
</template>

<script type="text/javascript">
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    components: {
        AppIcon
    },
    methods: {
        click () {
            this.$emit("click", this.field, (this.sort === "desc") ? "asc" : "desc")
        }
    },
    computed: {
        glyph () {
            if(this.hovered) {
                if(this.sort && this.sort.match(/^desc/i)) return "sort-asc"
                return "sort-desc"
            }
            if(this.sort && this.sort.match(/^desc/i)) return "sort-desc"
            return "sort-asc"
        }
    },
    props: {
        field: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        sort: {
            validator: prop => !prop || prop.match(/^(asc|desc)$/),
            required: true
        },
        type: {
            type: String,
            validator: prop => !!prop && prop.match(/^(amount|numeric|alpha)$/),
            deafult: "amount"
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hovered: false
        }
    }
}
</script>
