<template>
    <div
        v-if="!!prices && !!prices.length"
        class="price-picker"
    >
        <div
            class="columns"
        >
            <div
                v-for="price in ordered_prices"
                :key="price.id"
                class="column col-sm-12 mb-2"
            >
                <div
                    class="card"
                    @click.prevent="pick_price(price)"
                >
                    <div class="card-header">
                        <div class="card-title h5">
                            {{ price.nickname }}
                        </div>
                        <div
                            v-if="price_discounted(price)"
                            class="card-subtitle text-gray text-small text-strikethrough"
                        >
                            {{ stripe_price_regular_price(price) }}
                        </div>
                        <div
                            class="card-subtitle"
                        >
                            {{ price_price(price) }}
                            <span v-if="trialPeriod"> {{ translate("for the first month:::for the first {count} months", { count: trialPeriod }, trialPeriod) }}</span>
                        </div>
                    </div>
                    <div
                        v-if="(ordered_prices.length > 1) || !!discount_conditions(coupon, price)"
                        class="card-footer"
                    >
                        <div
                            v-if="!!discount_conditions(coupon, price)"
                            :class="{'text-dark': !is_selected_price(price), 'text-gray': is_selected_price(price)}"
                            class="text-small"
                        >
                            {{ discount_conditions(coupon, price) }}
                        </div>
                        <default-button
                            v-if="ordered_prices.length > 1"
                            :color="is_selected_price(price) ? 'primary' : 'regular'"
                            :block="true"
                            @click.prevent="pick_price(price)"
                        >
                            <app-icon v-if="is_selected_price(price)" glyph="check-circle" />
                            <span v-else>{{ translate("Select") }}</span>
                        </default-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import subscription_string_utilities from "./SubscriptionStringUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    mixins: [profile_utilities, subscription_string_utilities],
    components: {
        DefaultButton,
        AppIcon
    },
    mounted () {
        this.load()
    },
    methods: {
        load () {
            let invitation_token = null
            if(window.location.search) {
                const matches = window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)
                if(matches) invitation_token = matches[1]
            }
            this.$store.dispatch("FETCH_RECORDS", { entity: "price", query: { invitation_token, fields: ["fieldset::picker"] } }).then(() => {
                this.pick_price()
            })
        },
        is_selected_price (price) {
            if(!price || !this.price) return false
            return price.stripe_id === this.price.stripe_id
        },
        price_discounted (price) {
            return (!!this.coupon && (price.amount > 0)) || this.trialPeriod
        },
        price_price (price, raw) {
            return this.stripe_price_price(price, this.coupon, raw, this.trialPeriod)
        },
        pick_price (price) {
            if(!price) {
                const prices = this.prices
                if(prices.length) {
                    if(this.defaultPriceId) price = prices.find(price => price.id === this.defaultPriceId)
                    if(!price) price = prices.find(price => price.is_default) || prices[0]
                }
            }
            this.$emit("update:price", price)
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        prices () {
            const prices = this.entity_records("price")
            if(this.is_at_least_administrator) return prices
            return prices.filter(price => !price.is_private || (!!this.price && (price.id === this.price.id)))
        },
        ordered_prices () {
            return orderBy(this.prices, "amount", "asc")
        }
    },
    props: {
        price: {
            validator: prop => (prop === null) || (typeof prop === "object"),
            required: true
        },
        coupon: {
            validator: prop => (prop === null) || (typeof prop === "object"),
            default () {
                return null
            }
        },
        defaultPriceId: {
            type: Number,
            default: 0
        },
        trialPeriod: {
            type: Number,
            default: 0
        },
        editable: {
            type: Boolean,
            default: true
        }
    }
}
</script>
