<template>
    <div>
        <price-picker
            v-if="mode !== 'cc'"
            :price="price || null"
            :default-price-id="defaultPriceId"
            :trial-period="trialPeriod"
            :coupon="coupon"
            v-on="$listeners"
            class="mt-8"
        />

        <form-input
            v-if="!!price && !!price.amount"
            id="coupon_code"
            name="coupon_code"
            v-model="coupon_code"
            :required="false"
            :live="false"
            @input="coupon_fetch"
        >
            <template v-slot:label>{{ translate("Coupon") }}</template>
        </form-input>

        <StripeElements
            ref="stripe_elements"
            v-show="needs_cc"
            :stripe-key="app_context.stripe_key"
            #default="{ elements }"
            :class="{ cc_ready }"
        >
            <StripeElement
                ref="stripe_card"
                type="card"
                :elements="elements"
                :options="element_options"
                @ready="context_ready"
                @change="cc_ready = $event.complete"
            />
        </StripeElements>
        <div v-if="trialPeriod" class="text-small text-gray">{{ translate("Your credit card will not be charged until the end of your trial period. Providing it now simply makes sure there will be no interruptions when your trial period is over.") }}</div>
    </div>
</template>

<script type="text/javascript">
import ui_utilities from "@/nibnut/mixins/UiUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"

import PricePicker from "./PricePicker"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import { StripeElements, StripeElement } from "vue-stripe-elements-plus"

export default {
    mixins: [ui_utilities, string_utilities],
    components: {
        PricePicker,
        FormInput,
        StripeElements,
        StripeElement
    },
    watch: {
        subscribed: "reset",
        "coupon.stripe_id": "reset_coupon_code"
    },
    methods: {
        reset () {
            this.cc_ready = false
            this.coupon_code = ""
        },
        context_ready () {
            this.$emit("context-ready", this.$refs.stripe_elements, this.$refs.stripe_card)
        },
        reset_coupon_code () {
            if(this.coupon && (this.coupon.stripe_id !== this.coupon_code)) this.coupon_code = this.coupon.stripe_id
        },
        coupon_fetch () {
            if(!this.coupon_code) return Promise.resolve(null)

            return this.$store.dispatch("REBATE", { code: this.coupon_code }).then(coupon => {
                this.$emit("update:coupon", coupon)
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        needs_cc () {
            if(this.mode === "invite") return false
            return true
            /*
            if(this.mode === "cc") return true

            if(!this.subscribed) return !!this.price && !!this.price.amount

            return (!this.price || (!!this.price && !!this.price.amount))
            */
        }
    },
    props: {
        price: {
            type: Object
        },
        coupon: {
            default: null
        },
        subscribed: {
            type: Boolean,
            required: false
        },
        defaultPriceId: {
            type: Number,
            default: 0
        },
        mode: {
            validator: prop => !!prop && !!prop.match(/^(cc|subscribe|change|unsubscribe|resume|invite)$/),
            default: "change"
        },
        trialPeriod: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            cc_ready: false,
            coupon_code: "",
            element_options: {
                style: {
                    base: {
                        fontFamily: " -apple-system, system-ui, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif",
                        fontSize: "18px"
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.StripeElement {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    color: $body-font-color;
    display: block;
    font-size: $font-size;
    height: $control-size;
    line-height: $line-height;
    max-width: 100%;
    outline: none;
    padding: $control-padding-y $control-padding-x;
    position: relative;
    width: 100%;
}
</style>
