<template>
    <modal-dialog
        id="project-editor"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span v-if="!!record_id && !!edited_record" class="h5">{{ translate("Edit Project {name}", { name: edited_record.name }) }}</span>
            <span v-else class="h5">{{ translate("New Project") }}</span>
        </template>

        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
        >
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        <div class="h5">
                            {{ translate("General Information") }}
                            <harvest-button
                                main-endpoint="projects"
                                :main-id="edited_record.remote_id || 0"
                                class="ml-4"
                            />
                        </div>
                        <div>
                            <form-toggle-input
                                id="is_active"
                                name="is_active"
                                type="checkbox"
                                v-model="edited_record.is_active"
                                :required="false"
                                :saving="saving('is_active')"
                                :error="has_error('is_active')"
                                @input="save"
                            >
                                {{ translate('Active') }}
                            </form-toggle-input>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="columns">
                        <div class="column col-8">
                            <form-input
                                id="name"
                                name="name"
                                v-model="edited_record.name"
                                :required="true"
                                :saving="saving('name')"
                                :error="has_error('name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Name") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-4">
                            <form-input
                                id="code_suffix"
                                name="code_suffix"
                                v-model="edited_record.code_suffix"
                                :required="true"
                                :saving="saving('code_suffix')"
                                :error="has_error('code_suffix')"
                                @input="save"
                            >
                                <template v-slot:left_addon><span v-if="!!webApp.code_prefix" class="input-group-addon">{{ webApp.code_prefix }}-</span></template>
                                <template v-slot:label>{{ translate("Code") }}</template>
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-6">
                <div class="card-header">
                    <div class="card-title h5">
                        {{ translate("Fee") }}
                    </div>
                </div>
                <div class="card-body">
                    <div class="columns">
                        <div class="column col-3 col-md-12">
                            <form-toggle-input
                                id="is_fixed_fee"
                                name="is_fixed_fee"
                                type="radio"
                                v-model="edited_record.is_fixed_fee"
                                :required="false"
                                :saving="saving('is_fixed_fee')"
                                :error="has_error('is_fixed_fee')"
                                @input="save"
                            >
                                {{ translate('Fixed') }}
                            </form-toggle-input>
                        </div>
                        <div v-if="edited_record.is_fixed_fee" class="column col-3 col-md-6 col-sm-12">
                            <form-input
                                id="fee"
                                name="fee"
                                type="stripe"
                                v-model="edited_record.fee"
                                :required="true"
                                :saving="saving('fee')"
                                :error="has_error('fee')"
                                @input="save"
                            >
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">$</span>
                                </template>
                            </form-input>
                        </div>
                        <div v-if="edited_record.is_fixed_fee" class="column col-3 col-md-6 col-sm-12">
                            <form-input
                                id="budget"
                                name="budget"
                                type="stripe"
                                v-model="edited_record.budget"
                                :required="true"
                                :saving="saving('budget')"
                                :error="has_error('budget')"
                                @input="save"
                            >
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">h</span>
                                </template>
                            </form-input>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column col-3 col-md-12">
                            <form-toggle-input
                                id="is_not_fixed_fee"
                                name="is_fixed_fee"
                                type="radio"
                                v-model="edited_record.is_fixed_fee"
                                :true-value="false"
                                :false-value="true"
                                :required="false"
                                :saving="saving('is_fixed_fee')"
                                :error="has_error('is_fixed_fee')"
                                @input="save"
                            >
                                {{ translate('Variable') }}
                            </form-toggle-input>
                        </div>
                        <div v-if="!edited_record.is_fixed_fee" class="column col-3 col-md-6 col-sm-12">
                            <form-input
                                id="hourly_rate"
                                name="hourly_rate"
                                type="stripe"
                                v-model="edited_record.hourly_rate"
                                :required="true"
                                :saving="saving('hourly_rate')"
                                :error="has_error('hourly_rate')"
                                @input="save"
                            >
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">$&nbsp;/&nbsp;h</span>
                                </template>
                            </form-input>
                        </div>
                        <div v-if="!edited_record.is_fixed_fee" class="column col-3 col-md-6 col-sm-12">
                            <form-input
                                id="budget"
                                name="budget"
                                type="stripe"
                                v-model="edited_record.budget"
                                :required="true"
                                :saving="saving('budget')"
                                :error="has_error('budget')"
                                @input="save"
                            >
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">h</span>
                                </template>
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
        </base-form>

        <div v-if="!!record_id" class="text-center mt-8">
            <default-button
                flavor="link"
                color="error"
                @click.prevent="confirm_record_delete"
            >
                {{ translate("Delete Project") }}
            </default-button>
        </div>

        <confirmation
            v-if="confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />

        <template v-slot:footer>
            <div v-if="!record_id" class="text-center">
                <default-button
                    class="mr-2"
                    @click.prevent="close"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    class="ml-2"
                    @click.prevent="record_save"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import is_record_editor from "@/nibnut/mixins/IsRecordEditor"
import string_utilities from "@/nibnut/mixins/StringUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import HarvestButton from "@/custom/components/HarvestButton"

export default {
    name: "ContactEditor",
    mixins: [is_record_editor, string_utilities],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormToggleInput,
        HarvestButton,
        DefaultButton
    },
    watch: {
        projectId: "reload",
        "webApp.id": "reload",
        show: "maybe_reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.projectId
        },
        maybe_reload () {
            if(this.show) this.reload()
        },
        post_load () {
            if(!this.record_id) this.edited_record.contact_product_id = this.contactProductId
        },
        record_save () {
            const edited_record = this.edited_record
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(() => {
                this.close()
            })
        },
        confirm_record_delete () {
            const edited_record = this.edited_record
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Project"),
                    message: this.translate("Do you really want to delete the project \"{name}\"? It will also delete it on Harvest, and there is no undo...", { name: edited_record.name }),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        record_deleted (record) {
            this.close()
        },
        close () {
            this.shown = false
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_reload () {
            return !!this.profile_id && !!this.show
        },
        cleanup_before_reload () {
            return false
        },
        shell_data () {
            return { web_app_id: this.webApp.id }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        projectId: {
            type: Number,
            required: true
        },
        webApp: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "project",
            entityName: "project:::projects",
            relation_ids: []
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#project-editor {
    .card-title {
        display: flex;
        align-items: center;

        & > div {
            flex: 0 0 auto;

            &.h5 {
                flex: 1 0 auto;
            }

            & > .form-group {
                float: right;
                @media (max-width: $size-sm) {
                    float: none;
                }
            }
        }
    }
}
</style>
