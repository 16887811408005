<template>
    <page
        id="users-list"
        :title="translate('User:::Users', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <data-table
            id="users-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="inviting = true"
            @click="record_edit(entity, $event.id)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <div class="btn-group">
                        <default-button
                            :active="!state.filter_on || !state.filter"
                            @click.prevent="filter('role', null)"
                        >
                            {{ translate('Any Role') }}
                        </default-button>
                        <default-button
                            v-for="role in roles"
                            :key="role.id"
                            :active="!!state.filter_on && (state.filter===role.id)"
                            @click.prevent="filter('role', role.id)"
                        >
                            {{ translate(role.name) }}
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <p class="text-small">
                    <mailto-link
                        :email="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </p>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    {{ row.name }}
                    <div v-if="!!row.invitation_expires_at" class="text-gray text-small">
                        <span v-if="invitation_expired(row)">{{ translate("Invitation expired on") }}</span>
                        <span v-else>{{ translate("Invitation valid until") }}</span>
                        {{ row.invitation_expires_at | nibnut.date("YYYY-MM-DD [@] HH:mm") }}
                        <default-button
                            :title="translate('Re-send invitation')"
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="resend_invitation(row)"
                        >
                            <app-icon glyph="forward" />
                        </default-button>
                    </div>
                </div>
                <span v-else-if="field === 'email'">
                    <mailto-link
                        :email="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />

        <invite-user-dialog
            :show.sync="inviting"
            @invited="$router.push({ name: `${entity}.edit`, params: { id: $event.id } })"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities, is_user_inviter } from "../../nibnut/mixins"

import {
    DefaultButton,
    MailtoLink,
    AppIcon
} from "../../nibnut/components"
import {
    InviteUserDialog
} from "@/custom/dialogs"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, is_user_inviter],
    components: {
        DefaultButton,
        MailtoLink,
        AppIcon,
        InviteUserDialog
    },
    methods: {
        invitation_expired (row) {
            if(!!row && !!row.invitation_expires_at) return this.$dayjs(row.invitation_expires_at).isBefore()
            return false
        }
    },
    computed: {
        roles () {
            return Object.values(this.constants("roles"))
        }
    },
    data () {
        return {
            entity: "user",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                email: { label: "Email", sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            inviting: false
        }
    }
}
</script>

<style lang="scss">
#users-list {
    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
