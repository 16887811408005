<template>
    <div :id="state_identifier" class="card">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Maintenance") }}
            </div>
        </div>
        <div class="card-body">
            <div v-if="!is_empty">
                <div
                    v-for="ghost in ghosts"
                    :key="ghost.id"
                    class="toast toast-error"
                >
                    <app-icon glyph="ghost" class="mr-2" />{{ translate("{name} was last seen {ago}", { name: ghost.name, ago: nibnut_filter("nibnut.date_from_now", [ghost.last_monitored_at]) }) }}
                </div>

                <div v-if="!!state.total" class="mt-4">
                    <h6>{{ translate("Reports to review") }}</h6>
                    <data-table
                        :id="state_identifier"
                        :columns="visible_columns"
                        :rows="filtered_rows"
                        :search="state.search"
                        :total="state.total"
                        :found="state.found"
                        :current-page="state.page"
                        :total-pages="nb_pages"
                        :can-add="false"
                        @sort="sort_by"
                        @search="search"
                        @page="goto_page"
                        @click="view_row_details"
                    >
                        <template
                            v-slot:summary="{ row }"
                        >
                            <h6>{{ installation_name(row) }}</h6>
                            <div>
                                {{ row.period_from | nibnut.date }} - {{ row.period_to | nibnut.date }}
                            </div>
                            <ul class="text-gray text-small">
                                <li
                                    v-for="error in row.errors"
                                    :key="error.id"
                                >
                                    {{ error.name }}
                                </li>
                            </ul>
                        </template>
                        <template
                            v-slot:tbody="{ row, field }"
                        >
                            <div
                                v-if="field === 'name'"
                            >
                                {{ installation_name(row) }}
                            </div>
                            <div
                                v-else-if="field === 'errors'"
                            >
                                <ul class="text-gray text-small">
                                    <li
                                        v-for="error in row.errors"
                                        :key="error.id"
                                    >
                                        {{ error.name }}
                                    </li>
                                </ul>
                            </div>
                            <div
                                v-else-if="field === 'period_from'"
                            >
                                {{ row.period_from | nibnut.date }} - {{ row.period_to | nibnut.date }}
                            </div>
                            <span
                                v-else
                            >
                                {{ row[field] }}
                            </span>
                        </template>
                    </data-table>
                </div>
            </div>
            <div v-else class="empty">
                <div class="empty-icon">
                    <img src="@/assets/img/purr.png" />
                </div>
                <p class="empty-title h5">{{ translate("All good!") }}</p>
            </div>
            <installation-report-editor
                :show.sync="viewing"
                :installation-report-id="viewing_id"
            />
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"
import { mapGetters } from "vuex"

import { is_remote_data_table_source } from "@/nibnut/mixins"

import {
    AppIcon
} from "@/nibnut/components"
import {
    InstallationReportEditor
} from "@/custom/dialogs"

export default {
    name: "Maintenance",
    mixins: [is_remote_data_table_source],
    components: {
        AppIcon,
        InstallationReportEditor
    },
    methods: {
        post_load () {
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "installation",
                    query: {
                        per_page: 0,
                        page: 1,
                        filter_on: "preset",
                        filter: "ghosts",
                        fields: ["fieldset::dashboard"]
                    }
                }
            ).then(response => {
                this.ghost_ids = response.record_ids
            }).catch(error => {
                this.$error(error.message)
            })
        },
        filter_rows (rows) {
            if(this.state.filter_on) {
                return rows.filter((row) => {
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        search_rows (rows) {
            const search = this.state.search.toLowerCase()
            if(search.length >= this.min_search_length) {
                return this.rows.filter((row) => {
                    const keys = ["name", ...Object.keys(row)]
                    return keys.some((key) => {
                        let value = row[key]
                        if(key === "name") {
                            const installation = this.installation_for_row(row)
                            if(installation) value = installation[key]
                        }
                        return String(value).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return rows
        },
        installation_for_row (row) {
            return this.entity_record("installation", row.installation_id)
        },
        installation_name (row) {
            const installation = this.installation_for_row(row)
            return installation ? installation.name : this.translate("Unknown")
        },
        view_row_details (row) {
            this.viewing_id = row.id
            this.viewing = true
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "dashboard-maintenance"
        },
        is_empty () {
            return !this.raw_ghosts.length && !this.state.total
        },
        fields () {
            return ["fieldset::dashboard", "ns::installation;fieldset::dashboard"]
        },
        raw_ghosts () {
            return this.entity_records("installation", this.ghost_ids)
        },
        ghosts () {
            return orderBy(this.raw_ghosts, "last_monitored_at", "asc")
        }
    },
    data () {
        return {
            entity: "installation_report",
            relation_ids: ["installation"],
            columns: {
                name: { label: "Installation", sort: null, type: "alpha" },
                period_from: { label: "Period", sort: "asc", type: "numeric" },
                errors: { label: "Error(s)", sort: false }
                // errors: server needs upgrades or reboot, daily backup missing or invalid,
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "period_from",
                sort_dir: "asc",
                filter_on: "published_at",
                filter: "",
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            ghost_ids: [],
            viewing: false,
            viewing_id: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

#dashboard-maintenance {
    .empty {
        padding: $unit-8;

        img {
            max-height: 5rem;
        }
    }
    .toast + .toast {
        margin-top: $unit-2;
    }
    .table td ul {
        margin: 0;
    }
}
</style>
